import { SpinnerFullscreen } from '@busybytes/antd-extensions';
import { Layout } from 'antd';
import { TReducerRoot } from 'api/redux/types';
import ErrorScreen from 'app/Parts/Error/500';
import Footer from 'app/Parts/Footer/Footer';
import Header from 'app/Parts/Header/Header';
import LayoutCustom from 'app/Parts/Layout/Layout.styles';
import { PAGE, PUBLIC_ROUTE, TOOL } from 'app/router.constants';
import { ScrollToTop } from 'components/Utility/ScrollToTop';
import StaticContainer from 'containers/_global/StaticContainer/StaticContainer';
import { ACCOUNT_ROLE } from 'functions/shared/constants';
import {
  getLocalAccountRole,
  getLocalToken,
  getLoggingOut,
} from 'library/localStorage';
import { checkMinAccountRole } from 'library/snippets/security';
import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

const routesData: TRoutes = {
  public: [
    {
      path: `/`,
      render: lazy(
        () => import('containers/_global/LoginAndRegistration/Login')
      ),
    },
    {
      path: `/${PUBLIC_ROUTE.LOGIN}/:value`,
      render: lazy(
        () => import('containers/_global/LoginAndRegistration/Login')
      ),
    },
    {
      path: `/${PUBLIC_ROUTE.REGISTRATION}`,
      render: lazy(
        () => import('containers/_global/LoginAndRegistration/Registration')
      ),
    },
  ],
  // Note: first entry here is used for starting page redirect
  private: [
    {
      path: `/${PAGE.PATIENTS}`,
      render: lazy(() => import('containers/asistmedic/Patients/Patients')), // needed so that subpage works
      minRole: ACCOUNT_ROLE.patient.value,
      access: process.env.REACT_APP_AM === 'true',
    },
    {
      path: `/${PAGE.PATIENT}/:keyPatient`,
      render: lazy(
        () => import('containers/asistmedic/Patients/PatientDetails')
      ),
      minRole: ACCOUNT_ROLE.patient.value,
      access: process.env.REACT_APP_AM === 'true',
    },
    {
      path: `/${PAGE.STAFF}`,
      render: lazy(() => import('containers/asistmedic/Staff/Staff')),
      minRole: ACCOUNT_ROLE.coordinator.value,
      access: true,
    },
    {
      path: `/${TOOL.SETTINGS}`,
      render: lazy(() => import('containers/_global/Settings/Settings')), // needed so that subpage works
      minRole: ACCOUNT_ROLE.patient.value,
      access: true,
    },
    {
      path: `/${TOOL.SETTINGS}/general`,
      render: lazy(() => import('containers/_global/Settings/Settings')),
      minRole: ACCOUNT_ROLE.patient.value,
      access: true,
    },
    {
      path: `/${TOOL.SETTINGS}/login`,
      render: lazy(() => import('containers/_global/Settings/Settings')),
      minRole: ACCOUNT_ROLE.patient.value,
      access: true,
    },
    {
      path: `/${TOOL.SETTINGS}/notifications`,
      render: lazy(() => import('containers/_global/Settings/Settings')),
      minRole: ACCOUNT_ROLE.coordinator.value,
      access: true,
    },
    {
      path: `/${TOOL.SETTINGS}/admin`,
      render: lazy(() => import('containers/_global/Settings/Settings')),
      minRole: ACCOUNT_ROLE.admin.value,
      access: true,
    },
  ],
  error: [
    {
      path: `/${PAGE.P403}`,
      render: lazy(() => import('app/Parts/Error/403')),
    },
    {
      path: `/${PAGE.P404}`,
      render: lazy(() => import('app/Parts/Error/404')),
    },
    {
      path: `/${PAGE.P503}`,
      render: lazy(() => import('app/Parts/Error/503')),
    },
  ],
};

export default (): JSX.Element => {
  // Note: we need this to refresh the screen
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rx_refreshRouter = useSelector(
    (state: TReducerRoot) => state.GL_Auth.rx_refreshRouter
  );
  const isLoggedIn = getLocalToken() != null && getLocalAccountRole() != null;
  const isLoggingOut = getLoggingOut() != null;

  return (
    <BrowserRouter>
      <Suspense fallback={<SpinnerFullscreen />}>
        <ScrollToTop />
        {isLoggedIn && <StaticContainer />}

        <Routes>
          {/* Check if any private routes match */}
          {routesData.private.map((ele, index) => (
            <Route
              key={index}
              path={ele.path}
              element={
                // Uncomment for Maintenance
                // <Navigate to={'/' + PAGE.P503} replace />
                isLoggedIn &&
                checkMinAccountRole(ele.minRole ?? 0) &&
                ele.access !== false ? (
                  <LayoutCustom>
                    <Header />
                    <Layout.Content>
                      <ele.render />
                    </Layout.Content>
                    <Footer />
                  </LayoutCustom>
                ) : (
                  <Navigate
                    to={isLoggingOut ? PUBLIC_ROUTE.LANDING : '/' + PAGE.P403}
                    replace
                  />
                )
              }
              errorElement={<ErrorScreen />}
            />
          ))}
          {/* Check if any public routes match */}
          {routesData.public.map((ele, index) => (
            <Route
              key={index}
              path={ele.path}
              element={
                isLoggedIn && !isLoggingOut ? (
                  <Navigate to={routesData.private[0].path} replace />
                ) : (
                  <ele.render />
                )
              }
              errorElement={<ErrorScreen />}
            />
          ))}
          {/* Check for error pages */}
          {routesData.error.map((ele, index) => (
            <Route
              key={index}
              path={ele.path}
              element={
                isLoggedIn && !isLoggingOut ? (
                  <ele.render />
                ) : (
                  <Navigate to={PUBLIC_ROUTE.LANDING} replace />
                )
              }
              errorElement={<ErrorScreen />}
            />
          ))}
          {/* Special: mid-2021 we had an issue with PWA start link going to /images/icons/. To fix this, we catch it here. */}
          <Route
            path={'/images/icons/'}
            element={<Navigate to={PUBLIC_ROUTE.LANDING} replace />}
          />
          {/* If everything else fails, redirect to 404 page */}
          <Route
            path="*"
            element={<Navigate to={'/' + PAGE.P404} replace />}
            errorElement={<ErrorScreen />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

type TRoutes = {
  [name: string]: {
    path: string;
    render: React.LazyExoticComponent<React.ComponentType<any>>;
    minRole?: number;
    access?: boolean;
  }[];
};

import { Timestamp } from 'firebase/firestore';
import { ACCOUNT_ROLE, ID, STORAGE } from 'functions/shared/constants';
import {
  TAccount,
  TPatient,
  TPatientHistoryRecord,
} from 'functions/shared/types';
import { fileUpload } from 'library/firebase';
import { ReportingFE } from 'library/sentry';
import { showMessage } from 'library/snippets/ui_ux';
import _ from 'lodash';

export const getNewHistoryRecord = async (
  firebaseId: string,
  status: number,
  diagnosis: string,
  note: string | undefined,
  files: string[],
  userRole: number,
  userNameFirst: string,
  userNameLast: string,
  timestamp: number
): Promise<TPatientHistoryRecord<number>> => {
  const historyEntry: TPatientHistoryRecord<number> = {
    status: status,
    diagnosis: diagnosis,
    note: note ?? null,
    creator_role: userRole,
    creator_name: `${userNameFirst} ${userNameLast}`,
    files: [],
    time_created: Timestamp.fromMillis(timestamp),
  };
  historyEntry.files = await uploadFiles(
    `${STORAGE.patient_files}/${firebaseId}`,
    files
  );
  return historyEntry;
};

export const getUpdatedHistoryRecord = async (
  firebaseId: string,
  data: any,
  record: any
): Promise<TPatientHistoryRecord<number>> => {
  const historyEntry: TPatientHistoryRecord<number> = {
    status: data[ID.status],
    diagnosis: data[ID.diagnosis],
    note: data[ID.note] ?? null,
    creator_name: record.creator_name,
    creator_role: record.creator_role,
    files: [],
    time_created: record[ID.time_created],
  };
  historyEntry.files = await uploadFiles(
    `${STORAGE.patient_files}/${firebaseId}`,
    data[ID.file_upload]
  );
  return historyEntry;
};

export const uploadFiles = async (
  storagePath: string,
  fileArray: any[]
): Promise<string[]> => {
  if (fileArray?.length > 0) {
    showMessage('upload.inprogress', ID.loading, ID.key);
    const files = await fileUpload(storagePath, fileArray);
    showMessage('global.filesUploaded', ID.success, ID.key);
    return files;
  }
  return [];
};

export const multiSelectObjectToArray = (object: any[]): string[] => {
  if (_.isEmpty(object)) {
    return [];
  }
  return object.map((v) => v.value);
};

export const getConnectedPatientsForStaff = (
  account: TAccount,
  rx_patients: TPatient[]
): TPatient[] => {
  const role = Object.values(ACCOUNT_ROLE).find(
    (r) => r.value === account.role
  );
  if (role != null) {
    switch (account.role) {
      case ACCOUNT_ROLE.doctor.value:
        return rx_patients.filter(
          (patient: TPatient) => patient.doctors[account.key] != null
        );
      case ACCOUNT_ROLE.nurse.value:
        return rx_patients.filter(
          (patient: TPatient) => patient.nurses[account.key] != null
        );
      case ACCOUNT_ROLE.coordinator.value:
      case ACCOUNT_ROLE.supervisor.value:
      case ACCOUNT_ROLE.admin.value:
        return [];
      default:
        ReportingFE.Error(
          new Error(`Staff member has unwanted role: ${account.role}.`)
        );
        return [];
    }
  } else {
    ReportingFE.Error(new Error(`Unwanted user role: ${account.role}.`));
    return [];
  }
};

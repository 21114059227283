import { color, size } from '@busybytes/style-constants';
import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledHeader = styled(Layout.Header)`
  position: fixed;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
  padding: 0 40px;
  transition: all 0.3s;

  @media ${size.max_md} {
    padding: 0px 15px;
  }

  @media ${size.max_sm} {
    padding: 0px 10px;
  }

  &.collapsed {
    padding: 0 35px;
    @media ${size.max_md} {
      padding: 0px 20px;
    }
  }

  .isoLeft,
  .isoRight {
    width: 50%;
  }

  .isoLeft {
    display: flex;
    justify-content: flex-start;
    .ant-spin-nested-loading,
    .ant-spin-container {
      height: 100%;
    }
    .logo-link {
      display: flex;
      width: fit-content;
      height: 100%;
      align-items: center;
      justify-content: flex-start;
      transition: opacity 0.2s;
      &:hover {
        opacity: 0.85;
      }
      img {
        object-fit: cover;
        padding: 16px 0;
        height: 100%;
        max-width: 100%;
      }
    }
  }

  .isoRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .isoClientLogo {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .ant-spin-blur {
        img {
          border: none;
        }
      }
      img {
        padding: 8px;
        border: 1px solid ${color.border.border_light};
        border-radius: 4px;
        &:hover {
          border-color: ${color.border.border_default};
        }
      }
      @media ${size.max_xs} {
        display: none;
      }
    }

    .ant-spin-blur {
      .logo-section {
        min-width: 70px;
      }
    }

    .logo-section {
      max-width: 90px;
      width: 100%;
      img {
        max-width: 100%;
        max-height: 52px;
      }
      @media ${size.max_md} {
        max-width: 80px;
      }
    }
  }
`;

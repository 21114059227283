import {
  TAccountActionProps,
  TGetPatientHistoryProps,
  TInventoryActionProps,
  TLoginProps,
  TRefreshPatientHistoryProps,
  TSendPasswordResetProps,
  TSetLoginTimestampProps,
  TSubscribePatientInventoryProps,
  TSubscribePatientSheetProps,
  TSubscribePatientsProps,
  TUpdatePatientSheetProps,
  TUpdateUserSecurity,
  TUploadLogoProps,
} from 'api/redux/types';
import { IPdfPatientPageParams } from 'functions/shared/types';
import { createRoutine } from 'redux-saga-routines';

export default {
  // App
  subscribeAccounts: createRoutine('subscribeAccounts'),
  cancelAccounts: createRoutine('cancelAccounts'),
  sendActivityNotification: createRoutine('sendActivityNotification'),
  sendOnboardingNotification: createRoutine('sendOnboardingNotification'),
  setLoginTimestamp: createRoutine('setLoginTimestamp', {
    trigger: (payload: TSetLoginTimestampProps) => payload,
  }),
  accountAction: createRoutine('accountAction', {
    trigger: (payload: TAccountActionProps) => payload,
  }),
  setView: createRoutine('setView'),
  setModal: createRoutine('setModal'),
  setNotification: createRoutine('setNotification'),
  loadLogo: createRoutine('loadLogo'),
  uploadLogo: createRoutine('uploadLogo', {
    trigger: (payload: TUploadLogoProps) => payload,
  }),
  // getSettings: createRoutine('getSettings'),
  // updateSettingsField: createRoutine('updateSettingsField', {
  //   trigger: (payload: TUpdateSettingsFieldProps) => payload,
  // }),

  // Auth
  checkFirebaseAuth: createRoutine('checkFirebaseAuth'),
  login: createRoutine('login', {
    trigger: (payload: TLoginProps) => payload,
  }),
  logout: createRoutine('logout'),
  setRefreshRouter: createRoutine('setRefreshRouter'),
  setUserAccount: createRoutine('setUserAccount'),
  sendPasswordReset: createRoutine('sendPasswordReset', {
    trigger: (payload: TSendPasswordResetProps) => payload,
  }),
  registerUser: createRoutine('registerUser'),
  updateUserCommunication: createRoutine('updateUserCommunication'),
  updateUserSecurity: createRoutine('updateUserSecurity', {
    trigger: (payload: TUpdateUserSecurity) => payload,
  }),

  // Asistmedic
  subscribePatients: createRoutine('subscribePatients', {
    trigger: (payload: TSubscribePatientsProps) => payload,
  }),
  cancelPatients: createRoutine('cancelPatients'),
  subscribePatientSheet: createRoutine('subscribePatientSheet', {
    trigger: (payload: TSubscribePatientSheetProps) => payload,
  }),
  cancelPatientSheet: createRoutine('cancelPatientSheet'),
  subscribePatientInventory: createRoutine('subscribePatientInventory', {
    trigger: (payload: TSubscribePatientInventoryProps) => payload,
  }),
  cancelPatientInventory: createRoutine('cancelPatientInventory'),
  patientAction: createRoutine('patientAction'),
  patientStaffArrayAction: createRoutine('patientStaffArrayAction'),
  inventoryAction: createRoutine('inventoryAction', {
    trigger: (payload: TInventoryActionProps) => payload,
  }),
  updatePatientSheet: createRoutine('updatePatientSheet', {
    trigger: (payload: TUpdatePatientSheetProps) => payload,
  }),
  refreshPatientHistory: createRoutine('refreshPatientHistory', {
    trigger: (payload: TRefreshPatientHistoryProps) => payload,
  }),
  getPatientHistory: createRoutine('getPatientHistory', {
    trigger: (payload: TGetPatientHistoryProps) => payload,
  }),
  getPdfPatientPage: createRoutine('getPdfPatientPage', {
    trigger: (payload: IPdfPatientPageParams) => payload,
  }),
};

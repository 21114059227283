import GL_App from 'api/redux/_global/app/reducers';
import GL_Auth from 'api/redux/_global/auth/reducers';
import GL_Load from 'api/redux/_global/loadingReducer';
import AM from 'api/redux/asistmedic/reducers';
import { combineReducers } from 'redux';

export default combineReducers({
  GL_Load,
  GL_Auth,
  GL_App,
  AM,
});

/**
 * React-Intl middleware
 * https://formatjs.io/docs/react-intl/api/
 */

import { AppLocale, defaultLanguage } from 'assets/i18n';
import { getLanguagePreference } from 'library/localStorage';
import React from 'react';
import { createIntl, createIntlCache, useIntl } from 'react-intl';

const cache = createIntlCache();

/**
 * The intl object `IntlShape`.
 * More flexible than using `IntlMessages`.
 */
const intl = createIntl(
  {
    locale: AppLocale[getLanguagePreference() ?? defaultLanguage].localeHtml,
    messages: AppLocale[getLanguagePreference() ?? defaultLanguage].messages,
  },
  cache
);

/**
 * A `React.Component` with the translated text.
 */
const IntlMessages = (props: {
  id: string;
  values?: Record<string, any>;
}): any => {
  const intl = useIntl();
  return <>{intl.formatMessage({ id: props.id }, props.values)}</>;
};

export { intl, IntlMessages };

import { TLangIds } from 'functions/shared/types';

// ----- local account role
export function clearLocalAccountRole(): void {
  sessionStorage.removeItem('user_role');
  localStorage.removeItem('user_role');
}
export function getLocalAccountRole(): number | null {
  try {
    if (isSession()) {
      return Number(sessionStorage.getItem('user_role'));
    } else {
      return Number(localStorage.getItem('user_role'));
    }
  } catch (err) {
    return null;
  }
}
export function setLocalAccountRole(val: string): void {
  try {
    if (isSession()) {
      return sessionStorage.setItem('user_role', val);
    } else {
      return localStorage.setItem('user_role', val);
    }
  } catch (err) {}
}

// ----- language preference
export function clearLanguagePreference(): void {
  localStorage.removeItem('language_preference');
}
export function getLanguagePreference(): TLangIds | null {
  try {
    return localStorage.getItem('language_preference') as TLangIds;
  } catch (err) {
    return null;
  }
}
export function setLanguagePreference(val: string): void {
  try {
    return localStorage.setItem('language_preference', val);
  } catch (err) {}
}

// ----- local token
export function clearLocalToken(): void {
  sessionStorage.removeItem('id_token');
  localStorage.removeItem('id_token');
}
export function getLocalToken(): string | null {
  try {
    if (isSession()) {
      return sessionStorage.getItem('id_token');
    } else {
      return localStorage.getItem('id_token');
    }
  } catch (err) {
    clearLocalToken();
    return null;
  }
}
export function setLocalToken(val: string): void {
  try {
    if (isSession()) {
      return sessionStorage.setItem('id_token', val);
    } else {
      return localStorage.setItem('id_token', val);
    }
  } catch (err) {}
}

// ----- logging out
export function clearLoggingOut(): void {
  sessionStorage.removeItem('logging_out');
  localStorage.removeItem('logging_out');
}
export function getLoggingOut(): string | null {
  try {
    if (isSession()) {
      return sessionStorage.getItem('logging_out');
    } else {
      return localStorage.getItem('logging_out');
    }
  } catch (err) {
    return null;
  }
}
export function setLoggingOut(): void {
  try {
    if (isSession()) {
      return sessionStorage.setItem('logging_out', 'true');
    } else {
      return localStorage.setItem('logging_out', 'true');
    }
  } catch (err) {}
}

// ----- helper
const isSession = (): boolean => {
  return Object.keys(sessionStorage).some(
    (key) =>
      key.toLowerCase().includes('firebase:authuser') ||
      key.toLowerCase().includes('id_token')
  );
};

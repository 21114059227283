import actions from 'api/redux/actions';
import { TReducerAM, TReducerProps } from 'api/redux/types';
import { ACCOUNT_ROLE, ID } from 'functions/shared/constants';
import {
  TAccount,
  TDatabasePatient,
  TPatient,
  TPatientHistoryRecord,
} from 'functions/shared/types';
import { ReportingFE } from 'library/sentry';
import _ from 'lodash';

const initState: TReducerAM = {
  rx_patients: [],
  rx_accounts: [],
  rx_healthStaff: [[], []],
  rx_activityNotificationSuccess: false,
  rx_newAccountPassword: null,
  rx_patientSheetData: null,
  rx_patientInventory: [],
  rx_error: null,
  rx_pdfResultPatientPage: null,
  rx_refreshPatients: 0,

  // Helpers
  patientsRaw: [],
};

export default function reducer(
  state = initState,
  { type, payload }: TReducerProps
): Record<string, unknown> {
  switch (type) {
    // SUBSCRIBE ACCOUNTS REALTIME
    case actions.subscribeAccounts.SUCCESS:
      // Compare if changedValues only contains an updated timestamp
      // If so, then do NOT update the state
      let hasChanges = payload.changedValues.length === 0;
      for (const curr of payload.changedValues) {
        const prev = state.rx_accounts.find(
          (acc: TAccount) => acc.key === curr.key
        );
        if (
          !_.isEqual(
            _.omit(curr, [ID.time_last_login]),
            _.omit(prev, [ID.time_last_login])
          )
        ) {
          hasChanges = true;
        }
      }

      if (hasChanges) {
        // filter accounts by health staff (doctors, nurses)
        const _doctors: TAccount[] = [];
        const _nurses: TAccount[] = [];
        const _coordinators: TAccount[] = [];
        const _supervisors: TAccount[] = [];
        const _admins: TAccount[] = [];
        payload.allValues.forEach((v: TAccount) => {
          switch (v.role) {
            case ACCOUNT_ROLE.doctor.value:
              _doctors.push(v);
              break;
            case ACCOUNT_ROLE.nurse.value:
              _nurses.push(v);
              break;
            case ACCOUNT_ROLE.coordinator.value:
              _coordinators.push(v);
              break;
            case ACCOUNT_ROLE.supervisor.value:
              _supervisors.push(v);
              break;
            case ACCOUNT_ROLE.admin.value:
              _admins.push(v);
              break;
            default:
              break;
          }
        });

        // When accounts update, we also need to update patients
        const patients = addAccountInfoToPatients(
          state.patientsRaw,
          payload.allValues
        );
        return {
          ...state,
          rx_accounts: payload.allValues,
          rx_patients: patients,
          rx_healthStaff: [_doctors, _nurses],
        };
      }
      return state;

    // SUBSCRIBE PATIENTS REALTIME
    case actions.subscribePatients.SUCCESS:
      // If patient had history_entries, attach them again
      payload.map((curr: TDatabasePatient) => {
        const prev = state.rx_patients.find(
          (x: TPatient) => x.key === curr.key
        );
        if (
          curr.last_history_entries != null ||
          prev?.last_history_entries != null
        ) {
          curr.last_history_entries = getHistoryEntries(
            curr.last_history_entries,
            prev
          );
        }
        return curr;
      });

      // Add personal information from rx_accounts
      const patients = addAccountInfoToPatients(payload, state.rx_accounts);
      return {
        ...state,
        rx_patients: patients,
        patientsRaw: payload,
      };

    // SUBSCRIBE PATIENTSHEET REALTIME
    case actions.subscribePatientSheet.SUCCESS:
      return {
        ...state,
        rx_patientSheetData: payload,
      };

    // SUBSCRIBE PATIENT INVENTORY REALTIME
    case actions.subscribePatientInventory.SUCCESS:
      return {
        ...state,
        rx_patientInventory: payload,
      };

    // ACCOUNT ACTION
    case actions.accountAction.SUCCESS:
      return payload == null
        ? state
        : { ...state, rx_newAccountPassword: payload };
    case actions.accountAction.FULFILL:
      return { ...state, rx_newAccountPassword: null };

    // PATIENT ACTION
    case actions.patientAction.FAILURE:
      return { ...state, rx_error: payload };
    case actions.patientAction.FULFILL:
      return { ...state, rx_error: null };

    // REFRESH PATIENT HISTORY
    case actions.refreshPatientHistory.SUCCESS:
      const prev = state.rx_patients.find(
        (x: TPatient) => x.key === payload.key
      );
      if (prev != null) {
        if (payload.replaceAll) {
          prev.last_history_entries = payload.data.sort(
            (a: any, b: any) => b.time_created.seconds - a.time_created.seconds
          );
        } else {
          prev.last_history_entries = getHistoryEntries(payload.data, prev);
        }
      }

      return { ...state, rx_refreshPatients: state.rx_refreshPatients + 1 };
    case actions.refreshPatientHistory.FAILURE:
      return { ...state, rx_error: payload };
    case actions.refreshPatientHistory.FULFILL:
      return { ...state, rx_error: null };

    // GET PATIENT HISTORY
    case actions.getPatientHistory.SUCCESS:
      const patient2 = state.rx_patients.find((p) => p.key === payload.key);
      if (patient2 != null) {
        for (const historyEntry of payload.data) {
          if (patient2.last_history_entries == null) {
            patient2.last_history_entries = [];
          }
          if (
            !patient2.last_history_entries.find(
              (ele) => ele.key === historyEntry.key
            )
          ) {
            patient2.last_history_entries.push(historyEntry);
          }
        }
      }
      return { ...state, rx_refreshPatients: state.rx_refreshPatients + 1 };
    case actions.getPatientHistory.FAILURE:
      return { ...state, rx_error: payload };
    case actions.getPatientHistory.FULFILL:
      return { ...state, rx_error: null };

    // GET PDF PATIENT PAGE ACTION
    case actions.getPdfPatientPage.SUCCESS:
      return { ...state, rx_pdfResultPatientPage: payload };
    case actions.getPdfPatientPage.FULFILL:
      return { ...state, rx_pdfResultPatientPage: null };

    // SEND ACTIVITY NOTIFICATION ACTION
    case actions.sendActivityNotification.SUCCESS:
      return { ...state, rx_activityNotificationSuccess: true };
    case actions.sendActivityNotification.FULFILL:
      return { ...state, rx_activityNotificationSuccess: false };

    default:
      return state;
  }
}

// -------------------------------
//              HELPER
// -------------------------------

const getHistoryEntries = (
  currHistoryEntries?: TPatientHistoryRecord<number>[],
  prevPatient?: TPatient
): TPatientHistoryRecord<number>[] => {
  const finalEntries = prevPatient?.last_history_entries ?? [];
  if (currHistoryEntries != null) {
    for (const newEntry of currHistoryEntries) {
      const prevEntryIndex = finalEntries.findIndex(
        (ele) => ele.key === newEntry.key
      );
      if (prevEntryIndex >= 0) {
        finalEntries[prevEntryIndex] = newEntry;
      } else {
        finalEntries.push(newEntry);
      }
    }
  }
  return finalEntries.sort(
    (a, b) => b.time_created.seconds - a.time_created.seconds
  );
};

const addAccountInfoToPatients = (
  patientsDB: TDatabasePatient[],
  accounts: TAccount[]
): TPatient[] => {
  const res: TPatient[] = [];
  let hasReport = false;
  for (const patientDB of patientsDB) {
    const account: TAccount | undefined = accounts.find(
      (acc: TAccount) => acc.key === patientDB.key
    );
    if (account == null) {
      if (accounts.length > 0) {
        hasReport = true;
        checkShowReport(
          `Patient ${patientDB.key} does not have a corresponding account entry.`,
          accounts
        );
      }
      continue;
    }
    const patientFinal: TPatient = {
      key: patientDB.key ?? '',
      archived: patientDB.archived,
      last_history_entries: patientDB.last_history_entries,
      height: patientDB.height,
      weight: patientDB.weight,
      service: patientDB.service,
      onboarding: patientDB.onboarding,
      contact_person: patientDB.contact_person,
      doctors: {},
      nurses: {},
      weekly_report: patientDB.weekly_report,
      activity_notification: patientDB.activity_notification,
      time_last_update: patientDB.time_last_update,
      sheets_with_data: patientDB.sheets_with_data,
      current_doctor: patientDB.current_doctor,
      photo_ine: patientDB.photo_ine,

      name_first: account.name_first,
      name_last: account.name_last,
      email: account.email,
      birthday: account.birthday ?? '',
      gender: account.gender ?? ID.male,
      phone_number: account.phone_number ?? '',
      photo_profile: account.photo_profile,
      address_home: account.address_home,
      address_tax: account.address_tax,
      tax_id: account.tax_id,

      approved: account.approved,
    };
    // Fields doctors and nurses are arrays at the start and need to be converted to map
    for (const nurseId of patientDB.nurses) {
      const nurseInfo = accounts.find((acc: TAccount) => acc.key === nurseId);
      if (nurseInfo == null) {
        if (accounts.length > 0) {
          hasReport = true;
          checkShowReport(
            `Patient ${patientDB.key} contains nurse ${nurseId} without corresponding account entry.`,
            accounts
          );
        }
      } else {
        patientFinal.nurses[
          nurseId
        ] = `${nurseInfo.name_first} ${nurseInfo.name_last}`;
      }
    }
    // Doctors
    for (const doctorId of patientDB.doctors) {
      const doctorInfo = accounts.find((acc: TAccount) => acc.key === doctorId);
      if (doctorInfo == null) {
        if (accounts.length > 0) {
          hasReport = true;
          checkShowReport(
            `Patient ${patientDB.key} contains doctor ${doctorId} without corresponding account entry.`,
            accounts
          );
        }
      } else {
        patientFinal.doctors[
          doctorId
        ] = `${doctorInfo.name_first} ${doctorInfo.name_last}`;
      }
    }
    // Return
    res.push(patientFinal);
  }
  if (!hasReport) {
    if (reportingTimer != null) {
      reportingTimer = null;
    }
  }
  return res;
};

let reportingTimer: number | null = null;
const checkShowReport = (msg: string, accounts: TAccount[]) => {
  // This might happen because of async Cloud Function delay. To avoid unnecessary errors, keep a 15 seconds buffer period.
  const now = new Date().getTime();
  if (reportingTimer == null) {
    reportingTimer = now + 15000;
  } else if (now > reportingTimer) {
    ReportingFE.Warning(
      `${msg} Accounts: ${accounts.map((ele) => ele.email)}.`
    );
  }
};

import { blue, red } from '@ant-design/colors';
import { color } from '@busybytes/style-constants';
import { Popover } from 'antd';
import { projectStyles } from 'assets/styles/projectStyles';
import styled from 'styled-components';

/**
 * Holds both the styled text menu and the popover and its content.
 * On mobile <StyledMobileNavigation /> is used instead.
 */
export const StyledNavigation = styled.nav`
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;

  // remove default space from antd
  .ant-popover-inner-content {
    padding: 0;
  }
`;

/**
 * Holds the "text" menu, meaning what's not inside
 * the user account popover (pacients & staff)
 */
export const StyledTextMenu = styled.ul`
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: flex-end;

  li {
    transition: all 0.2s;
    display: inline-flex;
    height: 100%;
    border-bottom: 3px solid transparent;
    background-color: transparent;
    margin: 0 5px;
    &:hover {
      border-bottom-color: ${blue[2]};
    }
    &.active {
      a {
        color: ${blue.primary};
      }
      border-bottom-color: ${blue.primary};
    }
  }

  a {
    margin-top: 3px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    font-weight: 500;
    color: ${projectStyles.FONT_COLOR_BLACKISH};
  }
`;

/**
 * Custom styles for AntD's Popover
 */
export const StyledPopover = styled(Popover)`
  cursor: pointer;
  line-height: normal;
  position: relative;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  background-color: ${color.gray.light};
  margin-left: 15px;
  line-height: 1em;
  font-weight: bold;
`;

/**
 * Holds a unordered list of the Popover's content
 */
export const StyledPopoverContent = styled.ul`
  li {
    a {
      display: flex;
      align-items: center;
      padding: 12px 35px;
      min-width: 200px;
      color: ${projectStyles.FONT_COLOR_DARK};
      &:hover {
        background-color: ${color.gray.bright};
      }
      .anticon {
        margin-right: 9px;
      }
    }
  }
`;

export const StyledOverlay = styled.div`
  transition: all 0.3s;
  background-color: black;
  position: fixed;
  top: 64px;
  bottom: 0;
  right: 0;
  left: 0;
  ${(props) => {
    // @ts-ignore
    if (props.active) {
      return `
      pointer-events: all;
      opacity: 0.25;
      `;
    } else {
      return `
      pointer-events: none;
      opacity: 0;
      `;
    }
  }}
`;

/**
 * Mobile Nav!
 */
export const StyledMobileNavigation = styled.nav`
  background: white;
  transition: all 0.3s;
  position: fixed;
  top: 64px;
  bottom: 0;
  right: 0;

  ul {
    border-top: 1px solid ${color.border.border_light};
  }

  li {
    line-height: normal;
    transition: all 0.2s;
    border-left: 3px solid transparent;
    border-bottom: 1px solid ${color.border.border_light};
    background-color: transparent;

    &:hover {
      border-left-color: ${blue[2]};
      
      // logout item
      &:last-child {
        border-left-color: ${red[2]};
      }
    }

    &:active {
      background-color: ${color.gray.white_ambient};

      // logout item
      &:last-child {
        background-color ${red[0]};
      }
    }

    &.active {
      a {
        color: ${blue.primary};
      }
      border-left-color: ${blue.primary};
    }

    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 20px 20px;
      font-weight: 500;
      color: ${projectStyles.FONT_COLOR_BLACKISH};
    }
  }

  .anticon {
    margin-right: 9px;
  }
`;

/**
 * The hamburger button
 */
export const StyledHamburger = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;

  .icon {
    width: 20px;
    height: 14px;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: ${projectStyles.FONT_COLOR_DARK};
      opacity: 1;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      transform-origin: left center;

      &:nth-child(1) {
        top: 0px;
      }
      &:nth-child(2) {
        top: 6px;
      }
      :nth-child(3) {
        top: 12px;
      }
    }
  }

  &.open {
    .icon {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 0px;
        }
        &:nth-child(2) {
          opacity: 0;
          width: 0%;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 14px;
        }
      }
    }
  }
`;

import * as Sentry from '@sentry/react';
import 'antd/dist/antd.css';
import AppContainer from 'app/AppContainer';
import 'index.css';
import { createRoot } from 'react-dom/client';
import packageJson from '../package.json';
import * as serviceWorker from './serviceWorker';

const envCheck = ['asistmedic-dev'].every(
  (ele) => process.env.REACT_APP_FB_PROJECT_ID !== ele
);
const devCheck = ['localhost', '127.0.0.1'].every(
  (ele) => window.location.hostname !== ele
);
if (envCheck && devCheck) {
  Sentry.init({
    dsn: 'https://512ed1f08c8844718edf244b1d71cc3e@o274448.ingest.sentry.io/5356752',
    environment: process.env.REACT_APP_FB_PROJECT_ID,
    release: `asistmedic_webapp_${packageJson.version}`,
    ignoreErrors: [
      // This error means that ResizeObserver was not able to deliver all observations within a single animation frame. Can be safely ignored.
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',

      // We are not interested in the user loosing internet access.
      'La conexión de red se ha perdido.',
      'La conexión de red se perdió.',
      'Firebase: Error (auth/network-request-failed)', // Needs to be here as beforeSend is not called for this error.
    ],
    beforeSend(event) {
      let changedEvent: boolean = false;

      // Certain referrers
      if (
        document.referrer?.includes('http://baidu.com') &&
        event.message === 'QuotaExceededError'
      ) {
        changedEvent = true;
        console.info('Referrer: ', document.referrer);
      }

      // Firebase errors
      if (
        ['FirebaseError: internal'].some((ele) => event.message?.includes(ele))
      ) {
        changedEvent = true;
      }

      if (changedEvent) {
        console.warn('Change Sentry event severity level to INFO.');
        event.level = 'info' as Sentry.SeverityLevel;
      }

      return event;
    },
  });
}

createRoot(document.getElementById('root')!).render(<AppContainer />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// TODO this file needs update

export const imgToBlob = (url: string): Promise<XMLHttpRequest> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      resolve(this.response);
    };
    xhr.onerror = function (error) {
      reject(error);
    };
    xhr.send();
  });
};

export const getFileName = (name: string, index: string): string => {
  // Not the space was creating the issue, but the round parentheses was creating issue on my end, the name having parentheses, that thumb was not loading on my end.
  const split = name.split('.');
  return `${split[0]}-${new Date().getTime()}-${index}.${split.pop()}`;
};

export const isImageExtension = (ext: string): boolean => {
  return ['jpg', 'png', 'jpeg'].indexOf(ext) !== -1;
};

export const setFileName = (originalFileData: any, file: any): string => {
  if (
    originalFileData.fileName.split('.').length > 1 &&
    ['jpeg', 'jpg', 'png'].includes(originalFileData.fileName.split('.').pop())
  ) {
    return originalFileData.fileName;
  } else {
    return `${originalFileData.fileName}.${file.name.split('.').pop()}`;
  }
};

export const getThumbPath = (filePath: string): string => {
  // First, remove everything besides the last part
  const filePathParts = filePath.split('/');
  const fileNameComplete = filePathParts.pop();
  // Then, create the thumb file name
  const indexOfExt = fileNameComplete?.lastIndexOf('.');
  const fileName = fileNameComplete?.substring(0, indexOfExt);
  const fileExt = fileNameComplete?.substring((indexOfExt ?? 0) + 1);
  // Put path and name together
  return `${filePathParts.join('/')}/${fileName}@thumb.${fileExt}`;
};

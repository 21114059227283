import * as Sentry from '@sentry/react';

export const ReportingFE = {
  Error(errorObject: string | Error | unknown, extras?: string[]): void {
    Sentry.withScope(function (scope) {
      if (extras != null) {
        for (let i = 0; i < extras.length; i++) {
          scope.setExtra(`extra${i}`, extras[i]);
        }
      }
      Sentry.captureException(errorObject);
    });
    console.error('SENTRY ERROR:', errorObject, extras ?? '');
  },
  Warning(message: string, extras?: string[]): void {
    Sentry.withScope(function (scope) {
      if (extras != null) {
        for (let i = 0; i < extras.length; i++) {
          scope.setExtra(`extra${i}`, extras[i]);
        }
      }
      Sentry.captureMessage(message, 'warn' as Sentry.SeverityLevel);
    });
    console.warn('SENTRY WARN:', message, extras ?? '');
  },
  Info(message: string, extras?: string[]): void {
    Sentry.withScope(function (scope) {
      if (extras != null) {
        for (let i = 0; i < extras.length; i++) {
          scope.setExtra(`extra${i}`, extras[i]);
        }
      }
      Sentry.captureMessage(message, 'info' as Sentry.SeverityLevel);
    });
    console.warn('SENTRY INFO:', message, extras ?? '');
  },
};

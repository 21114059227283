import { Spin } from 'antd';
import { TReducerRoot } from 'api/redux/types';
import { StyledHeader } from 'app/Parts/Header/Header.styles';
import Navigation from 'app/Parts/Header/Parts/Navigation';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default (): JSX.Element => {
  // Redux State
  const rx_logo = useSelector((state: TReducerRoot) => state.GL_App.rx_logo);

  // Render
  return (
    <StyledHeader>
      <div className="isoLeft">
        <Spin spinning={rx_logo == null}>
          {rx_logo != null ? (
            <Link to={'/'} className="logo-link">
              <img
                src={rx_logo.mainUrl}
                alt={`${process.env.REACT_APP_CLIENT_NAME_FULL} Logo`}
              />
            </Link>
          ) : (
            <span />
          )}
        </Spin>
      </div>
      <div className="isoRight">
        <Navigation />
      </div>
    </StyledHeader>
  );
};

import { size } from '@busybytes/style-constants';
import { Layout } from 'antd';
import styled from 'styled-components';

export default styled(Layout)`
  min-height: 100vh;
  main.ant-layout-content {
    width: 100%;
    height: 100%;
    max-width: 1300px;
    margin: 64px auto;
    padding: 40px;
    @media ${size.max_md} {
      padding: 25px 15px;
    }
    @media ${size.max_sm} {
      padding: 25px 5px;
    }
  }
`;

import actions from 'api/redux/actions';
import { ID, STORAGE } from 'functions/shared/constants';
import { fileUpload, loadSingleFile } from 'library/firebase';
import { ReportingFE } from 'library/sentry';
import { sleep } from 'library/snippets/general';
import { showMessage } from 'library/snippets/ui_ux';
import { all, put, takeEvery } from 'redux-saga/effects';

function* loadLogo() {
  try {
    // @ts-ignore
    const file = yield loadSingleFile(
      `${STORAGE.settings}/${STORAGE.client_logo}`
    );
    yield put(actions.loadLogo.success(file));
  } catch (error) {
    ReportingFE.Error(error, ['loadLogo']);
    showMessage('error.generalError', ID.error);
    yield put(actions.loadLogo.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.loadLogo.fulfill());
  }
}

function* uploadLogo(action: ReturnType<typeof actions.uploadLogo.trigger>) {
  yield put(actions.uploadLogo.request());
  const { fileArray } = action.payload;
  try {
    showMessage('upload.inprogress', ID.loading, ID.key);
    yield fileUpload(STORAGE.settings, fileArray);
    showMessage('upload.success', ID.success, ID.key);
    yield put(actions.loadLogo());
    yield put(actions.uploadLogo.success());
  } catch (error) {
    ReportingFE.Error(error, ['uploadLogo']);
    showMessage('error.generalError', ID.error);
    yield put(actions.uploadLogo.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.uploadLogo.fulfill());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.loadLogo.TRIGGER, loadLogo),
    takeEvery(actions.uploadLogo.TRIGGER, uploadLogo),
    // takeEvery(actions.getSettings.TRIGGER, getSettings),
    // takeEvery(actions.updateSettingsField.TRIGGER, updateSettingsField),
  ]);
}

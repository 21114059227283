import { Result } from 'antd';
import ActionButtons from 'app/Parts/Error/ActionButtons';
import { IntlMessages } from 'components/Utility/IntlMessages';

export default (): JSX.Element => {
  return (
    <Result
      status="500"
      title="500"
      subTitle={<IntlMessages id="error.page500message" />}
      extra={<ActionButtons />}
    />
  );
};

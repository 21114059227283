import { size } from '@busybytes/style-constants';
import { message } from 'antd';
import { MessageDuration } from 'assets/definitions/AppConstants';
import { intl } from 'components/Utility/IntlMessages';
import { ID } from 'functions/shared/constants';
import _ from 'lodash';

export const showMessage = (
  intlString: string,
  type = ID.success,
  key = '',
  time = MessageDuration.normal
): any => {
  // @ts-ignore
  return message[type]({
    content: intl.formatMessage({
      id: intlString,
    }),
    duration:
      type === ID.error
        ? MessageDuration.long
        : type === ID.loading
        ? MessageDuration.loading
        : time,
    key: key,
  });
};

export const openInNewTab = (url: string): void => {
  const win = window.open(url);
  if (win != null) {
    win.focus();
  }
};

export const getView = (width: number | undefined): string => {
  if (width == null) {
    return ID.mobile_view;
  }
  if (width >= size.lg) {
    return ID.desktop_view;
  } else if (width >= size.md) {
    return ID.tablet_view;
  } else {
    return ID.mobile_view;
  }
};

export const isInternetExplorer = (): boolean => {
  // @ts-ignore
  return /*@cc_on!@*/ false || !!document.documentMode;
};

export const trimFormValues = (allValues: object | null): any => {
  if (allValues == null) {
    return allValues;
  }
  return Object.entries(allValues).reduce((prev, [key, value]) => {
    if (value != null && !Array.isArray(value)) {
      if (typeof value === ID.string) {
        return { ...prev, [key]: value?.replace(/  +/g, ' ').trim() };
      }
      if (typeof value === ID.object) {
        return { ...prev, [key]: trimFormValues(value) };
      }
    }
    return { ...prev, [key]: value };
  }, {});
};

export const sorterHelper = (
  objectA: any,
  objectB: any,
  key?: any,
  reverse?: any
) => {
  let a = _.get(objectA, key, objectA);
  let b = _.get(objectB, key, objectB);
  // Check for null
  if (a == null || b == null) {
    if (a != null) {
      return 1;
    } else if (b != null) {
      return -1;
    }
    return 0;
  }
  // Check if string
  if (typeof a === ID.string) {
    a = a.toLowerCase();
  }
  if (typeof b === ID.string) {
    b = b.toLowerCase();
  }
  // Compare
  if (a < b) {
    return reverse ? 1 : -1;
  } else if (a > b) {
    return reverse ? -1 : 1;
  }
  return 0;
};

import actions from 'api/redux/actions';
import { TReducerRoot } from 'api/redux/types';
import { IJWTDecode } from 'functions/shared/types';
import jwtDecode from 'jwt-decode';
import { useWindowSize } from 'library/hooks/useWindowSize';
import {
  getLocalAccountRole,
  getLocalToken,
  setLocalAccountRole,
} from 'library/localStorage';
import { getView } from 'library/snippets/ui_ux';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// This container is always visible, when logged in.
// Use it for screen-independent elements, like a static notification.
export default (): null => {
  // File members
  const mDispatch = useDispatch();
  const { user_id } = jwtDecode<IJWTDecode>(getLocalToken() ?? '');

  // Reducer
  const rx_user = useSelector((state: TReducerRoot) => {
    if (state.AM.rx_accounts.length === 0) {
      return false;
    }
    return state.AM.rx_accounts.find((u) => u.key === user_id);
  });

  // componentDidMount
  React.useEffect(() => {
    mDispatch(actions.subscribeAccounts());
    mDispatch(
      actions.subscribePatients({
        accountRole: Number(getLocalAccountRole()),
        uid: user_id,
      })
    );
    // mDispatch(actions.subscribeCommonSettings());
    mDispatch(actions.loadLogo());
  }, [mDispatch, user_id]);

  // componentDidUpdate when user changed (ignoring timestamp)
  React.useEffect(() => {
    // If rx_user === false, then rx_accounts are still loading
    if (rx_user === false) {
      return;
    }
    // If rx_accounts exist, but my ID was not found, logout
    if (rx_user == null) {
      // This happens when localstorage contains token from other ENV --> Logout
      mDispatch(actions.logout());
    } else {
      mDispatch(
        actions.setLoginTimestamp({
          uid: rx_user.key,
        })
      );
      mDispatch(actions.setUserAccount(rx_user));
      if (
        rx_user.role != null &&
        Number(getLocalAccountRole()) !== Number(rx_user.role)
      ) {
        setLocalAccountRole(rx_user.role.toString());
        // We need to reload to resubscribe all realtime listeners
        // Since this happens almost never, this solution is perfectly fine
        window.location.reload();
      }
    }
  }, [rx_user, mDispatch]);

  // Custom hook for global window resize listener
  const { width } = useWindowSize();
  React.useEffect(() => {
    const view = getView(width);
    mDispatch(actions.setView(view));
  }, [mDispatch, width]);

  // Render
  return null;
};

import {
  IAccountApproved,
  IAccountRole,
  IHealthStatus,
  TPatientSheet,
  TPatientSheetColumn,
} from './types';

export const ID = {
  // Screens
  add: 'add',
  emails: 'emails',
  message: 'message',
  added: 'added',
  remove: 'remove',
  title: 'title',
  description: 'description',
  push_status: 'push_status',
  update_date: 'update_date',
  send_push: 'send_push',
  repair_status: 'repair_status',
  timeline_description: 'timeline_description',
  timeline_repair_status: 'timeline_repair_status',
  timeline: 'timeline',
  mandate_id: 'mandate_id',
  mandate_ids: 'mandate_ids',
  mandate_data: 'mandate_data',
  mandate_name: 'mandate_name',
  date_creation: 'date_creation',
  date_incident: 'date_incident',
  is_public: 'is_public',
  date_sign: 'date_sign',
  owner_sign: 'owner_sign',
  status: 'status',
  plan: 'plan',
  shift: 'shift',
  owners: 'owners',
  name: 'name',
  owner_count: 'owner_count',
  object_name: 'object_name',
  phone_home: 'phone_home',
  role: 'role',
  archived: 'archived',
  weekly_report: 'weekly_report',
  activity_notification: 'activity_notification',
  active: 'active',
  send_email: 'send_email',
  recommended_by: 'recommended_by',
  tax_id: 'tax_id',
  phone_number: 'phone_number',
  phone_number_alt1: 'phone_number_alt1',
  phone_number_alt2: 'phone_number_alt2',
  onboarding_status: 'onboarding_status',
  onboarding_last_used_emails: 'onboarding_last_used_emails',
  patient_requirements: 'patient_requirements',
  relationship: 'relationship',
  phone_extension: 'phone_extension',
  extra_info: 'extra_info',
  company: 'company',
  email_external: 'email_external',
  component: 'component',
  replace_cost: 'replace_cost',
  remain_service_time: 'remain_service_time',
  state: 'state',
  status_auto: 'status_auto',
  current_deposit: 'current_deposit',
  current_balance: 'current_balance',
  minimum_threshold: 'minimum_threshold',
  gvz_percentage: 'gvz_percentage',
  yearly_correction_threshold: 'yearly_correction_threshold',
  status_description: 'status_description',
  cubature_insureance_value: 'cubature_insureance_value',
  elevator_stops: 'elevator_stops',
  electricity_usage: 'electricity_usage',
  quantity: 'quantity',
  insurance_value: 'insurance_value',
  offer: 'offer',
  estimate: 'estimate',
  sqmeter: 'sqmeter',
  condominium: 'condominium',
  tower: 'tower',
  house: 'house',
  stweg: 'stweg',
  joint_ownership: 'joint_ownership',
  type: 'type',
  comment: 'comment',
  comments: 'comments',
  count_available: 'count_available',
  count_total: 'count_total',
  city: 'city',
  street: 'street',
  number_ext: 'number_ext',
  number_int: 'number_int',
  rating: 'rating',
  updated_at: 'updated_at',
  salutation: 'salutation',
  name_first: 'name_first',
  name_last: 'name_last',
  name_first_alt: 'name_first_alt',
  name_last_alt: 'name_last_alt',
  number: 'number',
  address: 'address',
  address_home: 'address_home',
  address_tax: 'address_tax',
  height: 'height',
  weight: 'weight',
  additional_notes: 'additional_notes',
  current_doctor: 'current_doctor',
  process_type: 'process_type',
  items: 'items',
  date_end: 'date_end',
  date_start: 'date_start',
  vote_code: 'vote_code',
  vote_status: 'vote_status',
  vote_updated: 'vote_updated',
  vote_results: 'vote_results',
  apartments: 'apartments',
  base: 'base',
  factor: 'factor',
  calculation_base: 'calculation_base',
  renewal_date: 'renewal_date',
  renovation_date: 'renovation_date',
  construction_date: 'construction_date',
  ground_area: 'ground_area',
  optimal_start_balance: 'optimal_start_balance',
  immovable_type: 'immovable_type',
  immovable_id: 'immovable_id',
  sender_last_name: 'sender.last_name',
  sender_uid: 'sender.uid',
  incl_interest: 'incl_interest',
  incl_comp_images: 'incl_comp_images',
  progressbar_notification: 'progressbar_notification',
  pdf_size_error_notification: 'pdf_size_error_notification',
  name_account: 'name_account',
  balance: 'balance',
  account_number: 'account_number',
  name_bank: 'name_bank',
  rate_interest: 'rate_interest',
  rate_inflation: 'rate_inflation',
  editing_left_basic: 'editing_left_basic',
  editing_right_basic: 'editing_right_basic',
  editing_calculation_right: 'editing_calculation_right',
  uploading: 'uploading',
  synced: 'synced',
  missing: 'missing',
  public: 'public',
  private: 'private',
  merged: 'merged',
  none: 'none',
  none_reset: 'none_reset',
  action: 'action',
  file_size: 'file_size',
  file_upload: 'file_upload',
  file_upload_alt: 'file_upload_alt',
  file_upload_contract: 'file_upload_contract',
  file_upload_idcard_patient: 'file_upload_idcard_patient',
  file_upload_idcard_contact: 'file_upload_idcard_contact',
  file_upload_medical_indications: 'file_upload_medical_indications',
  file_upload_prescription_drugs: 'file_upload_prescription_drugs',
  file_upload_proof_of_deposit: 'file_upload_proof_of_deposit',
  file_upload_profile_photo: 'file_upload_profile_photo',
  last_updated_at: 'last_updated_at',
  submit: 'submit',
  on_submit: 'on_submit',
  back: 'back',
  last_history_entries: 'last_history_entries',
  access_rights: 'access_rights',
  email_admin_repair: 'email_admin_repair',
  communication: 'communication',
  content: 'content',
  percentage: 'percentage',
  details: 'details',
  patient: 'patient',
  patients: 'patients',
  doctor: 'doctor',
  doctors: 'doctors',
  nurse: 'nurse',
  nurses: 'nurses',
  primary: 'primary',
  link: 'link',
  birthday: 'birthday',
  gender: 'gender',
  male: 'male',
  female: 'female',
  checked: 'checked',
  default: 'default',
  small: 'small',
  middle: 'middle',
  large: 'large',
  diagnosis: 'diagnosis',
  remember_me: 'remember_me',
  year: 'year',
  month: 'month',
  day: 'day',
  days: 'days',
  repeat: 'repeat',
  click: 'click',
  top: 'top',
  left: 'left',
  right: 'right',
  bottomRight: 'bottomRight',
  personal: 'personal',
  record: 'record',
  note: 'note',
  history: 'history',
  inner: 'inner',
  start: 'start',
  add_new_row: 'add_new_row',
  line: 'line',
  danger: 'danger',
  staff: 'staff',
  gray: 'gray',
  special_care: 'special_care',
  personal_hygiene: 'personal_hygiene',
  sleep_and_rest: 'sleep_and_rest',
  specific_care: 'specific_care',
  nutrition: 'nutrition',
  material: 'material',
  inventory: 'inventory',
  ingress: 'ingress',
  egress: 'egress',
  parameters: 'parameters',
  vital_signs: 'vital_signs',
  rating_scales: 'rating_scales',
  medicine: 'medicine',
  medicines: 'medicines',
  address_tax_toggle: 'address_tax_toggle',

  // DB
  desc: 'desc',
  asc: 'asc',
  array: 'array',
  array_contains: 'array-contains',
  array_contains_any: 'array-contains-any',
  in: 'in',
  property_id: 'property_id',
  person_id: 'person_id',
  approved: 'approved',
  user_receiver_ids: 'user_receiver_ids',
  time_last_update: 'time_last_update',
  user_account: 'user_account',
  send_access: 'send_access',
  time_last_login: 'time_last_login',
  sheet_rows: 'sheet_rows',
  modified: 'modified',
  value: 'value',

  // CSS
  communicationDisabledBadge: 'communicationDisabledBadge',
  pendingUserBadge: 'pendingUserBadge',

  // Icons
  printer: 'printer',
  close: 'close',
  check: 'check',

  // Filter
  mandate_screen: 'mandate_screen',
  message_screen: 'message_screen',
  repair_screen: 'repair_screen',
  pinboard_screen: 'pinboard_screen',
  vote_screen: 'vote_screen',
  person_screen: 'person_screen',
  immofonds_home_screen: 'immofonds_home_screen',
  immomove_home_screen: 'immomove_home_screen',
  status_filter: 'status_filter',
  date_filter: 'date_filter',
  files_sync_filter: 'files_sync_filter',
  quantity_filter: 'quantity_filter',
  verification_filter: 'verification_filter',
  mandate_filter: 'mandate_filter',
  email_verified: 'email_verified',
  search_filter: 'search_filter',
  time_created: 'time_created',

  // Login
  email: 'email',
  email_alt: 'email_alt',
  email_forgot: 'email_forgot',
  zip_code: 'zip_code',
  password: 'password',
  current_password: 'current_password',
  repeat_password: 'repeat_password',

  // Success message
  info: 'info',
  warning: 'warning',
  success: 'success',
  error: 'error',
  loading: 'loading',
  failure: 'failure',

  // Products titles and routes
  asistmedic: 'asistmedic',
  immoapp: 'immoapp',
  immofonds: 'immofonds',
  immomove: 'immomove',
  account: 'account',
  admin: 'admin',
  portal: 'portal',
  tenant: 'tenant',

  // Files
  dropbox: 'dropbox',
  syncme: 'syncme',

  // Shuffle
  grid: 'grid',
  list: 'list',

  // Action
  insert: 'insert',
  update: 'update',
  resend_access: 'resend_access',
  edit_sheet_row: 'edit_sheet_row',
  delete: 'delete',
  view: 'view',
  update_record: 'update_record',
  insert_record: 'insert_record',
  delete_record: 'delete_record',

  // Modal
  patient_insert_edit_modal: 'patient_insert_edit_modal',
  staff_insert_edit_modal: 'staff_insert_edit_modal',
  patient_insert_record_modal: 'patient_insert_record_modal',
  inventory_insert_record_modal: 'inventory_insert_record_modal',
  patient_view_records_modal: 'patient_view_records_modal',
  user_modal: 'user_modal',
  image_modal: 'image_modal',
  forgot_access_modal: 'forgot_access_modal',
  account_email_modal: 'account_email_modal',
  account_password_modal: 'account_password_modal',
  register_modal: 'register_modal',
  activity_notification_modal: 'activity_notification_modal',

  card: 'card',

  // Notification
  if_pdf_notification: 'if_pdf_notification',

  // file types
  mandate: 'mandate',
  folder: 'folder',
  image: 'image',
  text: 'text',
  application: 'application',
  pdf: 'pdf',
  zip: 'zip',
  gif: 'gif',
  compressed: 'compressed',
  wordprocessingml_document: 'wordprocessingml_document',
  spreadsheetml_sheet: 'spreadsheetml_sheet',
  presentationml_presentation: 'presentationml_presentation',
  vnd_ms_powerpoint: 'vnd_ms_powerpoint',

  // Screen View
  mobile_view: 'mobile_view',
  desktop_view: 'desktop_view',
  tablet_view: 'tablet_view',
  inline: 'inline',
  vertical: 'vertical',
  horizontal: 'horizontal',

  // Random
  character_limit_reached: 'character_limit_reached',
  state_changed: 'state_changed',
  key: 'key',
  separator: 'separator',
  string: 'string',
  object: 'object',
  fixed: 'fixed',
};

export enum ERROR_CODE {
  ok = 'ok',
  cancelled = 'cancelled',
  unknown = 'unknown',
  invalid_argument = 'invalid-argument',
  deadline_exceeded = 'deadline-exceeded',
  not_found = 'not-found',
  already_exists = 'already-exists',
  permission_denied = 'permission-denied',
  resource_exhausted = 'resource-exhausted',
  failed_precondition = 'failed-precondition',
  aborted = 'aborted',
  out_of_range = 'out-of-range',
  unimplemented = 'unimplemented',
  internal = 'internal',
  unavailable = 'unavailable',
  data_loss = 'data-loss',
  unauthenticated = 'unauthenticated',
  auth_wrong_password = 'auth/wrong-password',
  auth_user_not_found = 'auth/user-not-found',
  auth_invalid_email = 'auth/invalid-email',
  auth_weak_password = 'auth/weak-password',
  auth_email_exists = 'auth/email-already-exists',
  auth_email_already_in_use = 'auth/email-already-in-use',
  storage_object_not_found = 'storage/object-not-found',
}

export enum SPACE_FUNCTIONS {
  gl_create_new_patient_sheet_day = 'gl_create_new_patient_sheet_day',
  gl_pdf_patient_page = 'gl_pdf_patient_page',
  gl_write_auth_account = 'gl_write_auth_account',
  gl_update_auth_email = 'gl_update_auth_email',
  gl_send_activity_notification = 'gl_send_activity_notification',
  gl_send_onboarding_notification = 'gl_send_onboarding_notification',
}

export enum URLS {
  control_cloud = 'https://europe-west1-immodigi-control.cloudfunctions.net/',
  store_immoapp_ios = 'https://apps.apple.com/de/app/immoapp/id1493436309',
  store_immoapp_android = 'https://play.google.com/store/apps/details?id=ch.immodigi.immoapp',
  store_aebi_ios = 'https://apps.apple.com/de/app/aebi-immo/id1470674410',
  store_aebi_android = 'https://play.google.com/store/apps/details?id=ch.peteraebi.aebiimmo',
  google_drive_viewer = 'https://docs.google.com/viewer?embedded=true&url=',
}

export enum DB {
  // Collections
  settings = 'settings',
  stats = 'stats',
  patients = 'patients',
  accounts = 'accounts',
  // Subcollections
  sub_emails = 'emails',
  sub_push_messages = 'push_messages',
  sub_sheets = 'sheets',
  sub_history = 'history',
  sub_inventory = 'inventory',
  // Docs
  doc_common_settings = 'common_settings',
}

export enum STORAGE {
  account_files = 'Account_files',
  patient_files = 'Patient_files',
  settings = 'Settings',
  client_logo = 'client_logo.png',
}

export const HEALTH_STATUS: {
  [status: string]: IHealthStatus;
} = Object.freeze({
  pending: {
    value: -1,
    intl_id: 'patient.health.-1',
    intl_id_register: '',
    color: 'gray',
  },
  critical: {
    value: 0,
    intl_id: 'patient.health.0',
    intl_id_register: 'patient.healthRegister.0',
    color: 'red',
  },
  serious: {
    value: 1,
    intl_id: 'patient.health.1',
    intl_id_register: 'patient.healthRegister.1',
    color: 'orange',
  },
  stable: {
    value: 2,
    intl_id: 'patient.health.2',
    intl_id_register: 'patient.healthRegister.2',
    color: 'green',
  },
  signed_off: {
    value: 3,
    intl_id: 'patient.health.3',
    intl_id_register: '',
    color: 'gray',
  },
});

export const ACCOUNT_ROLE: {
  [role: string]: IAccountRole;
} = Object.freeze({
  user_restricted: {
    value: 0,
    intl_id: 'role.userRestricted',
    color: 'gray',
  },
  patient: {
    value: 1,
    intl_id: 'role.patient',
    color: 'gray',
  },
  doctor: {
    value: 2,
    intl_id: 'role.doctor',
    color: 'cyan',
  },
  nurse: {
    value: 3,
    intl_id: 'role.nurse',
    color: 'pink',
  },
  coordinator: {
    value: 4,
    intl_id: 'role.coordinator',
    color: 'purple',
  },
  supervisor: {
    value: 5,
    intl_id: 'role.supervisor',
    color: 'lightblue',
  },
  admin: {
    value: 6,
    intl_id: 'role.admin',
    color: 'blue',
  },
});

export const ACCOUNT_APPROVED: {
  [role: string]: IAccountApproved;
} = Object.freeze({
  declined: {
    value: -1,
  },
  pending: {
    value: 0,
  },
  accepted: {
    value: 1,
  },
  with_account: {
    value: 2,
  },
});

export enum ONBOARDING_STATUS {
  'call' = 'call',
  'budget' = 'budget',
  'contract' = 'contract',
  'deposit' = 'deposit',
  'data_entry' = 'data_entry',
  'app_access' = 'app_access',
  'info_exchange' = 'info_exchange',
  'nurse_material' = 'nurse_material',
  'first_day' = 'first_day',
  'refund' = 'refund',
}

export const SHEET_COL_IDS = {
  dosis: 'dosis',
  via: 'via',
  frequency: 'frequency',
  schedule: 'schedule',
  comment: 'comment',
};

export const SHEET_ROW_IDS = {
  blood_pressure: 'blood_pressure',
  mean_arterial_pressure: 'mean_arterial_pressure',
  heart_rate: 'heart_rate',
  breathing_frequency: 'breathing_frequency',
  oxygen_saturation: 'oxygen_saturation',
  temperature: 'temperature',
  capillary_filling: 'capillary_filling',
  capillary_glycemia: 'capillary_glycemia',
  glycemia_preprandial: 'glycemia_preprandial',
  glycemia_postprandial: 'glycemia_postprandial',

  bristol: 'bristol',
  glasgow: 'glasgow',
  dolor_eva: 'dolor_eva',
  norton: 'norton',
  karnosky: 'karnosky',

  diuresis: 'diuresis',
  evacuations: 'evacuations',
  vomit: 'vomit',
  gastric_residue: 'gastric_residue',
  bleeding: 'bleeding',
  insensitive_losses: 'insensitive_losses',
  suction: 'suction',

  oral_hygiene: 'oral_hygiene',
  bath_in_bed: 'bath_in_bed',
  bath_in_shower: 'bath_in_shower',
  diaper_change: 'diaper_change',

  resting: 'resting',
  restless: 'restless',

  position_dli: 'position_dli',
  position_dl: 'position_dl',
  position_dd: 'position_dd',
  healing_of_wounds: 'healing_of_wounds',
  respiratory_therapy: 'respiratory_therapy',
  physiotherapy_exercises: 'physiotherapy_exercises',
  lab_sample: 'lab_sample',
  x_rays: 'x_rays',
  equipment_change: 'equipment_change',

  breakfast: 'breakfast',
  lunch: 'lunch',
  dinner: 'dinner',
  snack_morning: 'snack_morning',
  snack_afternoon: 'snack_afternoon',
};

export const SHEET_ROWS_DEFAULT: TPatientSheet = {
  medicines: {},
  parameters: {
    vital_signs: {
      [SHEET_ROW_IDS.blood_pressure]: {},
      [SHEET_ROW_IDS.mean_arterial_pressure]: {},
      [SHEET_ROW_IDS.heart_rate]: {},
      [SHEET_ROW_IDS.breathing_frequency]: {},
      [SHEET_ROW_IDS.oxygen_saturation]: {},
      [SHEET_ROW_IDS.temperature]: {},
      [SHEET_ROW_IDS.capillary_filling]: {},
      [SHEET_ROW_IDS.capillary_glycemia]: {},
      [SHEET_ROW_IDS.glycemia_preprandial]: {},
      [SHEET_ROW_IDS.glycemia_postprandial]: {},
    },
    rating_scales: {
      [SHEET_ROW_IDS.bristol]: {},
      [SHEET_ROW_IDS.glasgow]: {},
      [SHEET_ROW_IDS.dolor_eva]: {},
      [SHEET_ROW_IDS.norton]: {},
      [SHEET_ROW_IDS.karnosky]: {},
    },
  },
  ingress: {},
  egress: {
    [SHEET_ROW_IDS.diuresis]: {},
    [SHEET_ROW_IDS.evacuations]: {},
    [SHEET_ROW_IDS.vomit]: {},
    [SHEET_ROW_IDS.gastric_residue]: {},
    [SHEET_ROW_IDS.bleeding]: {},
    [SHEET_ROW_IDS.insensitive_losses]: {},
    [SHEET_ROW_IDS.suction]: {},
  },
  special_care: {
    personal_hygiene: {
      [SHEET_ROW_IDS.oral_hygiene]: {},
      [SHEET_ROW_IDS.bath_in_bed]: {},
      [SHEET_ROW_IDS.bath_in_shower]: {},
      [SHEET_ROW_IDS.diaper_change]: {},
    },
    sleep_and_rest: {
      [SHEET_ROW_IDS.resting]: {},
      [SHEET_ROW_IDS.restless]: {},
    },
    specific_care: {
      [SHEET_ROW_IDS.position_dli]: {},
      [SHEET_ROW_IDS.position_dl]: {},
      [SHEET_ROW_IDS.position_dd]: {},
      [SHEET_ROW_IDS.healing_of_wounds]: {},
      [SHEET_ROW_IDS.respiratory_therapy]: {},
      [SHEET_ROW_IDS.physiotherapy_exercises]: {},
      [SHEET_ROW_IDS.lab_sample]: {},
      [SHEET_ROW_IDS.x_rays]: {},
      [SHEET_ROW_IDS.equipment_change]: {},
    },
  },
  nutrition: {
    [SHEET_ROW_IDS.breakfast]: {},
    [SHEET_ROW_IDS.snack_morning]: {},
    [SHEET_ROW_IDS.lunch]: {},
    [SHEET_ROW_IDS.snack_afternoon]: {},
    [SHEET_ROW_IDS.dinner]: {},
  },
  material: {},
};

// This is sorted with other strings
export const SHEET_ROW_ORDER = {
  [SHEET_ROW_IDS.blood_pressure]: '__A',
  [SHEET_ROW_IDS.mean_arterial_pressure]: '__B',
  [SHEET_ROW_IDS.heart_rate]: '__C',
  [SHEET_ROW_IDS.breathing_frequency]: '__D',
  [SHEET_ROW_IDS.oxygen_saturation]: '__E',
  [SHEET_ROW_IDS.temperature]: '__F',
  [SHEET_ROW_IDS.capillary_filling]: '__G',
  [SHEET_ROW_IDS.capillary_glycemia]: '__H',
  [SHEET_ROW_IDS.glycemia_preprandial]: '__I',
  [SHEET_ROW_IDS.glycemia_postprandial]: '__J',

  [SHEET_ROW_IDS.breakfast]: '__A',
  [SHEET_ROW_IDS.snack_morning]: '__B',
  [SHEET_ROW_IDS.lunch]: '__C',
  [SHEET_ROW_IDS.snack_afternoon]: '__D',
  [SHEET_ROW_IDS.dinner]: '__E',
};

export const STRING_IDS = {
  access_file: '.access',
  input_separator: '___',
};

export const NURSE_SHEET_COLUMNS = {
  columns24: [...Array(24).keys()].map((key) => ({
    id: key,
  })) as TPatientSheetColumn[],
  columnsValue: [{ id: ID.value }] as TPatientSheetColumn[],
  columnsMedicines: [
    {
      id: SHEET_COL_IDS.dosis,
    },
    {
      id: SHEET_COL_IDS.via,
    },
    {
      id: SHEET_COL_IDS.frequency,
    },
    {
      id: SHEET_COL_IDS.schedule,
      infoTextId: 'patient.sheet.medicineScheduleInfo',
    },
    {
      id: SHEET_COL_IDS.comment,
      infoTextId: 'patient.sheet.medicineCommentInfo',
    },
  ] as TPatientSheetColumn[],
};

export const DATE_FORMAT = {
  display_day_full: 'dddd',
  display_day_complete: 'dddd, DD.MM.YYYY',
  display_full: 'DD.MM.YYYY - HH:mm',
  display_normal: 'DD.MM.YYYY',
  display_named: 'DD MMM YYYY',
  display_short: 'DD.MM.',
  compare_normal: 'DD-MM-YYYY',
  iso_8601: 'YYYY-MM-DD', // https://en.wikipedia.org/wiki/ISO_8601
  time: 'HH:mm',
  year: 'YYYY',
};

export const MAX_IMAGE_SIZE_DEFAULT = 1280;
export const MAX_IMAGE_SIZE_EMAIL = 768;

export const IMAGE_TYPES_WITH_THUMB = ['png', 'jpg', 'jpeg', 'webp'];
export const IMAGE_TYPES_ALL = ['png', 'jpg', 'jpeg', 'webp', 'svg', 'gif'];

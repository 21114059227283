import {
  ApiOutlined,
  BankOutlined,
  CarryOutOutlined,
  DollarOutlined,
  FileDoneOutlined,
  FileOutlined,
  InfoCircleOutlined,
  MedicineBoxOutlined,
  PhoneOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { intl } from 'components/Utility/IntlMessages';
import { ID, ONBOARDING_STATUS } from 'functions/shared/constants';

export const Validation = {
  max_text_length: 5000,
  regex_name: /^[a-zA-ZÀ-ÖØ-öø-ÿ ,.'-d]+$/u,
  phone_number: /^\+?[- \d()]+$/,
};

export const FormFieldValidation = {
  password: [
    {
      required: true,
      message: intl.formatMessage({ id: 'form.fieldRequired' }),
      validateTrigger: ID.on_submit,
    },
    {
      min: 6,
      message: intl.formatMessage({
        id: 'form.passwordCharacterValidation',
      }),
      validateTrigger: ID.on_submit,
    },
  ],
};

export const PatientSheetEditType = {
  text: 'text',
  number: 'number',
  checkmark: 'checkmark',
};

// Can be removed once patient screen is refactored
export const MandateScreen = {
  missing: 0,
  synced: 1,
  files_status: {
    missing: {
      intl_id: 'global.missing',
      value: 'missing',
    },
    synced: {
      intl_id: 'global.synced',
      value: 'synced',
    },
  },
  active_inactive_status: {
    inactive: {
      intl_id: 'global.inactive',
      value: 'inactive',
    },
    active: {
      intl_id: 'global.active',
      value: 'active',
    },
  },
};

export const AdminScreen = {
  admin_tabs: {
    general_tab: 1,
    staff_tab: 2,
    import_tab: 3,
    stats_tab: 4,
  },
};

export const SettingsTabs = {
  general: 'general',
  login: 'login',
  notifications: 'notifications',
  admin: 'admin',
};

export const PaginationSize = ['20', '100', '250'];

export const UserScreen = {
  email_verification: {
    notVerifiedYet: {
      intl_id: 'global.notVerifiedYet',
      value: 0,
    },
    verified: {
      intl_id: 'global.verified',
      value: 1,
    },
  },
};

export const ImageTypes = 'image/*';
export const PDFTypes = 'application/pdf';
export const FileExtensions = {
  images: ['png', 'jpg', 'jpeg', 'gif', 'tif', 'tiff'],
  videos: ['mp4', 'wmv', 'avi'],
  pdf: ['pdf'],
};

export const FilterData = {
  max_search_results: 8,
};

export const SearchLimit = {
  firebase_array_contains_limit: 10,
};

export const MessageDuration = {
  short: 3,
  normal: 4,
  long: 6,
  loading: 20,
};

export const RegisterPatientRequirementsSelection = [
  {
    value: 'oxygen_device',
    intl_id: 'register.oxygenDevice',
  },
  {
    value: 'intravenous_drug_administration',
    intl_id: 'register.intravenousDrugAdministration',
  },
  {
    value: 'central_catheter_management',
    intl_id: 'register.centralCatheterManagement',
  },
  {
    value: 'nutrition_via_probe_or_catheter',
    intl_id: 'register.nutritionViaProbeOrCatheter',
  },
  {
    value: 'wound_management',
    intl_id: 'register.woundManagement',
  },
  {
    value: 'stomata_management',
    intl_id: 'register.stomataManagement',
  },
  {
    value: 'urinary_catheter_management',
    intl_id: 'register.urinaryCatheterManagement',
  },
  {
    value: 'other',
    intl_id: 'register.other',
  },
];

export const RegisterShiftSelection = [
  {
    value: 'hours_8',
    intl_id: 'register.hours8',
  },
  {
    value: 'hours_12',
    intl_id: 'register.hours12',
  },
  {
    value: 'hours_24',
    intl_id: 'register.hours24',
  },
];

export const RegisterOnboardingStatusSelection = [
  {
    value: ONBOARDING_STATUS.call,
    intl_id: 'register.statusCall',
    icon: PhoneOutlined,
  },
  {
    value: ONBOARDING_STATUS.budget,
    intl_id: 'register.statusBudget',
    icon: DollarOutlined,
  },
  {
    value: ONBOARDING_STATUS.contract,
    intl_id: 'register.statusContract',
    icon: FileOutlined,
  },
  {
    value: ONBOARDING_STATUS.deposit,
    intl_id: 'register.statusDeposit',
    icon: BankOutlined,
  },
  {
    value: ONBOARDING_STATUS.data_entry,
    intl_id: 'register.statusDataEntry',
    icon: FileDoneOutlined,
  },
  {
    value: ONBOARDING_STATUS.app_access,
    intl_id: 'register.statusAppAccess',
    icon: ApiOutlined,
  },
  {
    value: ONBOARDING_STATUS.info_exchange,
    intl_id: 'register.statusInfoExchange',
    icon: InfoCircleOutlined,
  },
  {
    value: ONBOARDING_STATUS.nurse_material,
    intl_id: 'register.statusNurseMaterial',
    icon: MedicineBoxOutlined,
  },
  {
    value: ONBOARDING_STATUS.first_day,
    intl_id: 'register.statusFirstDay',
    icon: CarryOutOutlined,
  },
  {
    value: ONBOARDING_STATUS.refund,
    intl_id: 'register.statusRefund',
    icon: WalletOutlined,
  },
];

export const RegisterPlanSelection = [
  {
    value: 'basic',
    intl_id: 'register.basic',
  },
  {
    value: 'premium',
    intl_id: 'register.premium',
  },
  {
    value: 'vip',
    intl_id: 'register.vip',
  },
];

export const InventoryType = [
  {
    value: ID.material,
    intl_id: 'inventory.type.material',
  },
  {
    value: ID.medicine,
    intl_id: 'inventory.type.medicine',
  },
];

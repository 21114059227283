import { notification } from 'antd';
import { getUserAccount } from 'api/redux/_global/reduxHelper';
import actions from 'api/redux/actions';
import { intl } from 'components/Utility/IntlMessages';
import { AuthError, onAuthStateChanged, SignInMethod } from 'firebase/auth';
import {
  ACCOUNT_APPROVED,
  DB,
  ERROR_CODE,
  ID,
  SPACE_FUNCTIONS,
} from 'functions/shared/constants';
import { IUpdateAuthEmailParams, TAccount } from 'functions/shared/types';
import {
  callCloudFunction,
  firebaseLogin,
  firebaseLogout,
  getCurrentUser,
  getDatabaseDocument,
  getFirebaseAuth,
  reauthenticateWithCredentialHelper,
  resetPassword,
  sendVerificationEmail,
  updateDatabaseDocument,
  updatePasswordHelper,
} from 'library/firebase';
import {
  clearLocalAccountRole,
  clearLocalToken,
  getLocalToken,
  setLocalAccountRole,
  setLocalToken,
  setLoggingOut,
} from 'library/localStorage';
import { ReportingFE } from 'library/sentry';
import { sleep } from 'library/snippets/general';
import { showMessage } from 'library/snippets/ui_ux';
import { eventChannel } from 'redux-saga';
import { all, put, select, take, takeEvery } from 'redux-saga/effects';

function* login(action: ReturnType<typeof actions.login.trigger>) {
  yield put(actions.login.request());
  try {
    // Login to FB
    // @ts-ignore
    const fbLoginData = yield firebaseLogin(
      SignInMethod.EMAIL_PASSWORD,
      action.payload
    );
    // Check if email verified
    if (fbLoginData.user.emailVerified === false) {
      notification.error({
        message: intl.formatMessage({
          id: 'auth.emailNotVerified',
        }),
        description: null,
      });
      yield sendVerificationEmail();
      yield put(actions.login.failure());
      return;
    }
    // Check if user exists in DB
    // @ts-ignore
    const doc = yield getDatabaseDocument(
      `${DB.accounts}/${fbLoginData.user.uid}`
    );
    if (!doc.exists()) {
      ReportingFE.Error(
        new Error(`User with id does not exist: ${fbLoginData.user.uid}.`)
      );
      notification.error({
        message: intl.formatMessage({
          id: 'error.generalError',
        }),
        description: null,
      });
      yield put(actions.login.failure());
      // Return here still triggers finally
      return;
    }
    // Check if user has account
    if (doc.data().approved < ACCOUNT_APPROVED.with_account.value) {
      ReportingFE.Error(
        new Error(`User with id is not approved: ${fbLoginData.user.uid}.`)
      );
      notification.warning({
        message: intl.formatMessage({
          id: 'auth.notApproved',
        }),
        description: null,
      });
      yield put(actions.login.failure());
      // Return here still triggers finally
      return;
    }
    // Get token and set in localstorage
    // @ts-ignore
    const token = yield fbLoginData.user.getIdToken();
    if (token == null) {
      ReportingFE.Error(
        new Error(`GetIdToken failed for user: ${fbLoginData.user.uid}.`)
      );
      notification.error({
        message: intl.formatMessage({
          id: 'error.generalError',
        }),
        description: null,
      });
      yield put(actions.login.failure());
      // Return here still triggers finally
      return;
    }
    yield setLocalToken(token);
    yield setLocalAccountRole(doc.data().role);
    yield put(actions.setUserAccount({ key: doc.id, ...doc.data() }));
    // This triggers rerender of AppRouter and redirects to main page
    yield put(actions.setRefreshRouter());
    // Return
    yield put(actions.login.success());
  } catch (error) {
    let message = '';
    const err = error as AuthError;
    switch (err.code) {
      case ERROR_CODE.auth_user_not_found:
      case ERROR_CODE.auth_wrong_password:
        message = intl.formatMessage({
          id: 'auth.invalidLogin',
        });
        break;
      case ERROR_CODE.auth_invalid_email:
        message = intl.formatMessage({ id: 'auth.invalidEmail' });
        break;
      default:
        if (err.message == null) {
          message = intl.formatMessage({
            id: 'error.generalError',
          });
        } else {
          message = err.message;
        }
        break;
    }
    notification.error({
      message: message,
      description: null,
    });
    yield put(actions.login.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.login.fulfill());
  }
}

function* logout() {
  yield put(actions.logout.request());
  // Cancel realtime listeners
  // yield put(actions.cancelCommonSettings());
  yield put(actions.cancelAccounts());
  yield put(actions.cancelPatients());
  yield put(actions.cancelPatientSheet());
  // Set logging out so that AppRouter behaves correctly
  setLoggingOut();
  // Clear localStorage
  clearLocalToken();
  clearLocalAccountRole();
  yield put(actions.setUserAccount(null));
  // This triggers rerender of AppRouter
  yield put(actions.setRefreshRouter());
  // Firebase logout
  firebaseLogout();
  // Return
  yield put(actions.logout.success());
}

// This is called on app start and runs every time the FB changes auth status
function* checkFirebaseAuth() {
  while (true) {
    // If FB is not auth but localToken exists, logout
    const { user } = yield take(authEventsChannel);
    if (!user && getLocalToken()) {
      yield put(actions.logout());
    }
  }
}
const authEventsChannel = eventChannel((emitter) => {
  const unsubscribe = onAuthStateChanged(getFirebaseAuth(), (user) => {
    emitter({ user });
  });
  // Return a function that can be used to unregister listeners when the saga is cancelled
  // NOTE: unused at the moment
  return () => unsubscribe();
});

function* sendPasswordReset(
  action: ReturnType<typeof actions.sendPasswordReset.trigger>
) {
  yield put(actions.sendPasswordReset.request());
  try {
    yield resetPassword(action.payload.email);
    showMessage('login.resetPasswordSuccess', ID.success);
    yield put(actions.sendPasswordReset.success());
    yield put(actions.setModal(ID.none));
  } catch (error) {
    const err = error as AuthError;
    if (err.code === ERROR_CODE.auth_user_not_found) {
      // This is normal --> no error reporting needed
      showMessage('error.emailNotFound', ID.error);
    } else {
      showMessage('error.generalError', ID.error);
      ReportingFE.Error(err, ['sendPasswordReset']);
    }
    yield put(actions.sendPasswordReset.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.sendPasswordReset.fulfill());
  }
}

function* updateUserCommunication({ payload }: any) {
  yield put(actions.updateUserCommunication.request());
  // This can be updated over time for other notification settings
  const { settingsValue } = payload;
  try {
    const currentUser = getCurrentUser();
    if (currentUser == null) {
      throw new Error('Current user is null.');
    }
    yield updateDatabaseDocument(`${DB.accounts}/${currentUser.uid}`, {
      communication: settingsValue,
    });
    showMessage('account.notificationSettingsUpdated', ID.success);
    yield put(actions.updateUserCommunication.success());
  } catch (error) {
    ReportingFE.Error(error, ['updateUserCommunication']);
    showMessage('error.generalError', ID.error);
    yield put(actions.updateUserCommunication.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.updateUserCommunication.fulfill());
  }
}

function* updateUserSecurity(
  action: ReturnType<typeof actions.updateUserSecurity.trigger>
) {
  yield put(actions.updateUserSecurity.request());
  const { current_email, current_password, update, email, password } =
    action.payload;
  try {
    const currentUser = getCurrentUser();
    if (currentUser == null) {
      throw new Error('Current user is null.');
    }

    switch (update) {
      case ID.email:
        const functionParams: IUpdateAuthEmailParams = {
          uid: currentUser.uid,
          email,
        };

        // update the new email address
        // why cloud fn? => to avoid sending confirmation email
        // @ts-ignore
        const res = yield callCloudFunction(
          SPACE_FUNCTIONS.gl_update_auth_email,
          functionParams
        );

        if (res.data.error != null) {
          throw { code: res.data.error };
        }

        // update accounts' collection
        yield updateDatabaseDocument(`${DB.accounts}/${currentUser.uid}`, {
          email: email,
        });

        showMessage('account.emailChanged', ID.success);
        break;
      case ID.password:
        yield reauthenticateWithCredentialHelper(
          current_email,
          current_password
        );
        yield updatePasswordHelper(password);
        showMessage('account.passwordChanged', ID.success);
        break;
      default:
        const rx_userAccount: TAccount = yield select(getUserAccount);
        throw new Error(
          `User: ${rx_userAccount.key} - Payload: ${JSON.stringify(
            action.payload
          )}`
        );
    }
    // Close modal
    yield put(actions.updateUserSecurity.success());
    yield put(actions.setModal(ID.none_reset));
  } catch (error) {
    const err = error as AuthError;
    switch (err.code) {
      case ERROR_CODE.auth_invalid_email:
        showMessage('auth.invalidEmail', ID.error);
        break;
      case ERROR_CODE.auth_email_exists:
        showMessage('auth.emailExists', ID.error);
        break;
      case ERROR_CODE.auth_wrong_password:
        showMessage('auth.wrongPassword', ID.error);
        break;
      default:
        ReportingFE.Error(err, ['updateUserSecurity']);
        showMessage('error.generalError', ID.error);
        break;
    }
    yield put(actions.updateUserSecurity.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.updateUserSecurity.fulfill());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.checkFirebaseAuth.TRIGGER, checkFirebaseAuth),
    takeEvery(actions.login.TRIGGER, login),
    takeEvery(actions.logout.TRIGGER, logout),
    takeEvery(actions.sendPasswordReset.TRIGGER, sendPasswordReset),
    takeEvery(actions.updateUserCommunication.TRIGGER, updateUserCommunication),
    takeEvery(actions.updateUserSecurity.TRIGGER, updateUserSecurity),
  ]);
}

import { getUserAccount } from 'api/redux/_global/reduxHelper';
import actions from 'api/redux/actions';
import { MessageDuration } from 'assets/definitions/AppConstants';
import axios, { AxiosResponse } from 'axios';
import { AuthError, SignInMethod } from 'firebase/auth';
import {
  arrayRemove,
  arrayUnion,
  DocumentData,
  documentId,
  DocumentSnapshot,
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  QuerySnapshot,
  serverTimestamp,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import {
  ACCOUNT_APPROVED,
  ACCOUNT_ROLE,
  DB,
  ERROR_CODE,
  ID,
  SPACE_FUNCTIONS,
  STORAGE,
} from 'functions/shared/constants';
import {
  IAccountEntry,
  ICreateNewPatientSheetDayParams,
  ISendActivityNotification,
  ISendOnboardingNotification,
  IUpdateAuthEmailParams,
  IWriteAuthAccountParams,
  TDatabasePatient,
  TPatient,
  TPdfResultBlob,
} from 'functions/shared/types';
import {
  addDatabaseDocument,
  callCloudFunction,
  deleteDatabaseDocument,
  firebaseLogin,
  firebaseLogout,
  getCloudFunctionUrl,
  getDatabaseCollectionRef,
  getDatabaseDocument,
  getDatabaseDocumentRef,
  getDatabaseNewDocumentRef,
  getDocsByQuery,
  setDatabaseDocument,
  snapshotAsArray,
  updateDatabaseDocument,
} from 'library/firebase';
import { ReportingFE } from 'library/sentry';
import { sleep } from 'library/snippets/general';
import {
  getNewHistoryRecord,
  getUpdatedHistoryRecord,
  multiSelectObjectToArray,
  uploadFiles,
} from 'library/snippets/sagas';
import { showMessage } from 'library/snippets/ui_ux';
import _ from 'lodash';
import { eventChannel } from 'redux-saga';
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

// REALTIME
let listenerAccounts: Unsubscribe | null = null;
function* subscribeAccounts() {
  try {
    // This makes sure that the database listener is only active once
    if (listenerAccounts != null) {
      listenerAccounts();
    }
    yield put(actions.subscribeAccounts.request());

    // Note: If one of the 2 fn finishes, both are cancelled
    yield race({
      task: call(function* () {
        const channel = eventChannel((emitter) => {
          listenerAccounts = onSnapshot(
            query(
              getDatabaseCollectionRef(DB.accounts),
              orderBy(ID.role, 'desc'),
              orderBy('name_last', 'asc')
            ),
            (snapshot: QuerySnapshot) => {
              const allValues = snapshotAsArray(snapshot, true);
              const changedValues = snapshot
                .docChanges()
                .filter((ele: any) => ele.type === ID.modified)
                .map((ele: any) => ({ ...ele.doc.data(), key: ele.doc.id }));
              emitter({
                allValues,
                changedValues,
              });
            },
            (error: any) => {
              ReportingFE.Error(error, ['listenerAccounts1']);
            }
          );
          return () => listenerAccounts?.();
        });
        while (true) {
          const { allValues, changedValues } = yield take(channel);
          yield put(
            actions.subscribeAccounts.success({ allValues, changedValues })
          );
        }
      }),
      // If cancel is called, the code continues to execute
      cancel: take(actions.cancelAccounts.TRIGGER),
    });

    // We cancel the database listener
    listenerAccounts?.();
    listenerAccounts = null;
  } catch (error) {
    ReportingFE.Error(error, ['listenerAccounts2']);
    listenerAccounts?.();
    listenerAccounts = null;
    showMessage('error.generalError', ID.error);
    yield put(actions.subscribeAccounts.failure());
  } finally {
    // Not needed for realtime actions
  }
}

// REALTIME
let listenerPatients: Unsubscribe | null = null;
function* subscribePatients(
  action: ReturnType<typeof actions.subscribePatients.trigger>
) {
  let previousPatients: TPatient[] = [];
  const { accountRole, uid } = action.payload;
  try {
    // This makes sure that the database listener is only active once
    if (listenerPatients != null) {
      listenerPatients();
    }
    yield put(actions.subscribePatients.request());

    // Note: If one of the 2 fn finishes, both are cancelled
    yield race({
      task: call(function* () {
        const channel = eventChannel((emitter) => {
          listenerPatients = onSnapshot(
            (() => {
              switch (accountRole) {
                case ACCOUNT_ROLE.doctor.value:
                  return query(
                    getDatabaseCollectionRef(DB.patients),
                    where(ID.doctors, 'array-contains', uid),
                    where(ID.archived, '==', false)
                  );
                case ACCOUNT_ROLE.nurse.value:
                  return query(
                    getDatabaseCollectionRef(DB.patients),
                    where(ID.nurses, 'array-contains', uid),
                    where(ID.archived, '==', false)
                  );
                case ACCOUNT_ROLE.coordinator.value:
                case ACCOUNT_ROLE.supervisor.value:
                case ACCOUNT_ROLE.admin.value:
                  return getDatabaseCollectionRef(DB.patients);
                default:
                  // For patient, and in case of error for every other role
                  return query(
                    getDatabaseCollectionRef(DB.patients),
                    where(documentId(), '==', uid)
                  );
              }
            })(),
            async (snapshot: QuerySnapshot) => {
              // First, return all patients
              const res = snapshotAsArray(snapshot, true);
              emitter(res);

              // Then, fetch the first history entry from subcollection for each changed doc
              const promises = [];
              for (const curr of res) {
                const prev = previousPatients.find(
                  (x: TPatient) => x.key === curr.key
                );
                if (
                  !_.isEqual(
                    _.omit(curr, [
                      ID.time_last_update,
                      ID.last_history_entries,
                    ]),
                    _.omit(prev, [ID.time_last_update, ID.last_history_entries])
                  )
                ) {
                  promises.push(
                    (async () => {
                      const lastHistoryEntries = await getDocsByQuery(
                        query(
                          getDatabaseCollectionRef(
                            `${DB.patients}/${curr.key}/${DB.sub_history}`
                          ),
                          orderBy(ID.time_created, 'desc'),
                          limit(3)
                        )
                      );
                      curr.last_history_entries = snapshotAsArray(
                        lastHistoryEntries,
                        true
                      );
                    })()
                  );
                }
              }
              await Promise.all(promises);
              emitter(res);

              // Set variable so we can compare on next run
              previousPatients = res;
            },
            (error: any) => {
              ReportingFE.Error(error, ['listenerPatients1']);
            }
          );
          return () => listenerPatients?.();
        });
        while (true) {
          // @ts-ignore
          const payload = yield take(channel);
          yield put(actions.subscribePatients.success(payload));
        }
      }),
      // If cancel is called, the code continues to execute
      cancel: take(actions.cancelPatients.TRIGGER),
    });

    // We cancel the database listener
    listenerPatients?.();
    listenerPatients = null;
  } catch (error) {
    ReportingFE.Error(error, ['listenerPatients2']);
    listenerPatients?.();
    listenerPatients = null;
    showMessage('error.generalError', ID.error);
    yield put(actions.subscribePatients.failure());
  } finally {
    // Not needed for realtime actions
  }
}

// REALTIME
let listenerPatientSheet: Unsubscribe | null = null;
function* subscribePatientSheet(
  action: ReturnType<typeof actions.subscribePatientSheet.trigger>
) {
  const { keyPatient, keySheet } = action.payload;
  try {
    // This makes sure that the database listener is only active once
    if (listenerPatientSheet != null) {
      listenerPatientSheet();
    }
    yield put(actions.subscribePatientSheet.request());

    // Note: If one of the 2 fn finishes, both are cancelled
    yield race({
      task: call(function* () {
        const channel = eventChannel((emitter) => {
          listenerPatientSheet = onSnapshot(
            getDatabaseDocumentRef(
              `${DB.patients}/${keyPatient}/${DB.sub_sheets}/${keySheet}`
            ),
            async (doc: DocumentSnapshot<DocumentData>) => {
              if (doc.exists()) {
                emitter({
                  ...doc.data(),
                  key: keySheet,
                });
              } else {
                // Doc doesn't exist yet, e.g. because it is a new day
                // Call CloudFunction to create the new DB entry
                const functionParams: ICreateNewPatientSheetDayParams = {
                  key_sheet: keySheet,
                  key_patient: keyPatient,
                };
                const res = await callCloudFunction(
                  SPACE_FUNCTIONS.gl_create_new_patient_sheet_day,
                  functionParams
                );
                // @ts-ignore
                if (res.data.error != null) {
                  // @ts-ignore
                  throw { code: res.data.error };
                }
                // Note: technically it is not necessary to return here, since the realtime listener is called again once the document is created. But to be sure, we do return it double.
                emitter({
                  // @ts-ignore
                  ...res.data,
                  key: keySheet,
                });
              }
            },
            (error: any) => {
              ReportingFE.Error(error, ['listenerPatientSheet1']);
            }
          );
          return () => listenerPatientSheet?.();
        });
        while (true) {
          // @ts-ignore
          const payload = yield take(channel);
          yield put(actions.subscribePatientSheet.success(payload));
        }
      }),
      // If cancel is called, the code continues to execute
      cancel: take(actions.cancelPatientSheet.TRIGGER),
    });

    // We cancel the database listener
    listenerPatientSheet?.();
    listenerPatientSheet = null;
  } catch (error) {
    ReportingFE.Error(error, ['listenerPatientSheet2']);
    listenerPatientSheet?.();
    listenerPatientSheet = null;
    showMessage('error.generalError', ID.error);
    yield put(actions.subscribePatientSheet.failure());
  } finally {
    // Not needed for realtime actions
  }
}

// REALTIME
let listenerPatientInventory: Unsubscribe | null = null;
function* subscribePatientInventory(
  action: ReturnType<typeof actions.subscribePatientInventory.trigger>
) {
  const { keyPatient } = action.payload;
  try {
    // This makes sure that the database listener is only active once
    if (listenerPatientInventory != null) {
      listenerPatientInventory();
    }
    yield put(actions.subscribePatientInventory.request());

    // Note: If one of the 2 fn finishes, both are cancelled
    yield race({
      task: call(function* () {
        const channel = eventChannel((emitter) => {
          listenerPatientInventory = onSnapshot(
            getDatabaseCollectionRef(
              `${DB.patients}/${keyPatient}/${DB.sub_inventory}`
            ),
            (snapshot: QuerySnapshot) => {
              const res = snapshotAsArray(snapshot, true);
              emitter(res);
            },
            (error: any) => {
              ReportingFE.Error(error, ['listenerPatientInventory1']);
            }
          );
          return () => listenerPatientInventory?.();
        });
        while (true) {
          // @ts-ignore
          const payload = yield take(channel);
          yield put(actions.subscribePatientInventory.success(payload));
        }
      }),
      // If cancel is called, the code continues to execute
      cancel: take(actions.cancelPatientInventory.TRIGGER),
    });

    // We cancel the database listener
    listenerPatientInventory?.();
    listenerPatientInventory = null;
  } catch (error) {
    ReportingFE.Error(error, ['listenerPatientInventory2']);
    listenerPatientInventory?.();
    listenerPatientInventory = null;
    showMessage('error.generalError', ID.error);
    yield put(actions.subscribePatientInventory.failure());
  } finally {
    // Not needed for realtime actions
  }
}

function* accountAction(
  action: ReturnType<typeof actions.accountAction.trigger>
) {
  yield put(actions.accountAction.request());
  const { actionName, key, data } = action.payload;

  const accountEntry: IAccountEntry = {
    // Set later
    birthday: null,
    phone_number: null,
    photo_profile: null,
    address_home: {
      street: '',
      number_ext: '',
      number_int: '',
      zip_code: '',
      city: '',
      additional_notes: null,
    },
    address_tax: null,
    tax_id: null,

    // Set now
    role: data.role,
    name_first: data.name_first,
    name_last: data.name_last,
    email: data.email,
    gender: data.gender,
    approved: data.approved,
  };

  let res: any, functionParams: IWriteAuthAccountParams;

  try {
    switch (actionName) {
      case ID.insert:
        // Upload photo, if set
        // @ts-ignore
        const filesInsert = yield uploadFiles(
          `${STORAGE.account_files}/${key}`,
          data.file_upload
        );
        accountEntry.photo_profile =
          filesInsert.length > 0 ? filesInsert[0] : null;

        functionParams = {
          uid: key,
          email: accountEntry.email,
          name_first: accountEntry.name_first,
          name_last: accountEntry.name_last,
          role: accountEntry.role,
          approved: data.approved,
          send_access: data.send_access,
          action: actionName,
        };

        // @ts-ignore
        res = yield callCloudFunction(
          SPACE_FUNCTIONS.gl_write_auth_account,
          functionParams
        );

        if (res.data.error != null) {
          throw { code: res.data.error };
        }

        // set ACCOUNTS entry
        yield setDatabaseDocument(`${DB.accounts}/${key}`, accountEntry);

        yield put(actions.accountAction.success(res?.data?.password));
        showMessage('message.staffAdded', ID.success);
        break;

      case ID.resend_access:
        showMessage('global.actionInProgress', ID.loading, ID.key);
        functionParams = {
          uid: key,
          email: accountEntry.email,
          name_first: accountEntry.name_first,
          name_last: accountEntry.name_last,
          role: accountEntry.role,
          approved: true,
          send_access: true,
          action: actionName,
        };
        // @ts-ignore
        res = yield callCloudFunction(
          SPACE_FUNCTIONS.gl_write_auth_account,
          functionParams
        );
        // Update ACCOUNT entry
        yield updateDatabaseDocument(`${DB.accounts}/${key}`, {
          approved: ACCOUNT_APPROVED.with_account.value,
        });
        yield put(actions.accountAction.success());
        showMessage('auth.resentAccessSuccess', ID.success, ID.key);

        break;
      case ID.update:
        // Upload photo, if set
        // @ts-ignore
        const filesUpdate = yield uploadFiles(
          `${STORAGE.account_files}/${key}`,
          data.file_upload
        );
        accountEntry.photo_profile =
          filesUpdate.length > 0 ? filesUpdate[0] : null;

        // check if the email was changed,
        // if so, we call the `gl_update_auth_email` cloud function
        // @ts-ignore
        const accountDoc = yield getDatabaseDocument(`${DB.accounts}/${key}`);
        if (accountEntry.email !== accountDoc.data().email) {
          const functionParams: IUpdateAuthEmailParams = {
            uid: key,
            email: accountEntry.email,
          };
          // @ts-ignore
          const res = yield callCloudFunction(
            SPACE_FUNCTIONS.gl_update_auth_email,
            functionParams
          );

          if (res.data.error != null) {
            throw { code: res.data.error };
          }
        }

        // update ACCOUNTS entry
        yield updateDatabaseDocument(`${DB.accounts}/${key}`, accountEntry);

        yield put(actions.accountAction.success());
        yield put(actions.setModal(ID.none_reset));
        showMessage('message.staffUpdated', ID.success);
        break;

      case ID.delete:
        yield deleteDatabaseDocument(`${DB.accounts}/${key}`);
        showMessage('message.staffDeleted', ID.success);
        yield put(actions.accountAction.success());
        break;

      default:
        // @ts-ignore
        const rx_userAccount = yield select(getUserAccount);
        throw new Error(
          `User: ${rx_userAccount.key} - Payload: ${JSON.stringify(
            action.payload
          )}`
        );
    }
  } catch (error) {
    const err = error as AuthError;
    if (
      err?.code != null &&
      [ERROR_CODE.auth_email_exists, ERROR_CODE.auth_email_already_in_use].some(
        (e) => e === err.code
      )
    ) {
      showMessage('auth.emailExists', ID.error);
    } else {
      ReportingFE.Error(error, ['accountAction']);
      showMessage('error.generalError', ID.error);
    }
    yield put(actions.accountAction.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.accountAction.fulfill());
  }
}

function* patientAction({ payload }: any) {
  yield put(actions.patientAction.request());
  const { actionName, key, data, fetchHistoryLength } = payload;
  // @ts-ignore
  const rx_userAccount = yield select(getUserAccount);

  const timestamp = Date.now();

  const accountEntry: IAccountEntry = {
    role: ACCOUNT_ROLE.patient.value,
    name_first: data.name_first,
    name_last: data.name_last,
    email: data.email,
    phone_number: data.phone_number ?? null,
    gender: data.gender,
    birthday: data.birthday,
    approved: data.approved,
    address_home: data.address_home ?? null,
    address_tax: data.address_tax_toggle ? data.address_tax ?? null : null,
    tax_id: data.tax_id ?? null,
    photo_profile: null,
  };

  const patientEntry: TDatabasePatient = {
    // Set later
    sheets_with_data: [],
    photo_ine: null,
    time_last_update: null,

    // Set here
    archived: data.archived ?? false,
    current_doctor: data.current_doctor ?? null,
    nurses: multiSelectObjectToArray(data.nurses),
    doctors: multiSelectObjectToArray(data.doctors),
    weekly_report: data.weekly_report ?? [],
    activity_notification: data.activity_notification ?? [],
    height: data.height ?? null,
    weight: data.weight ?? null,
    service: {
      shift: data.shift ?? null,
      plan: data.plan ?? null,
      patient_requirements: data.patient_requirements ?? [],
      recommended_by: data.recommended_by ?? null,
      tax_id: data.tax_id ?? null,
      photo_of_deposit: null,
      contract: null,
    },
    onboarding: {
      status: data.onboarding_status ?? [],
      last_used_emails: data.onboarding_last_used_emails ?? [],
    },
    contact_person: {
      name_first: data.name_first_alt ?? null,
      name_last: data.name_last_alt ?? null,
      relationship: data.relationship ?? null,
      email: data.email_alt ?? null,
      phone_number: data.phone_number_alt1 ?? null,
      phone_number_alt: data.phone_number_alt2 ?? null,
      photo_ine: null,
    },
  };

  // Create local function so it can be used for insert and update
  const uploadAllFiles = async (key: string) => {
    const promises = [];
    // Upload photo, if set.
    promises.push(
      uploadFiles(
        `${STORAGE.account_files}/${key}`,
        data[ID.file_upload_profile_photo]
      ).then(
        (res) => (accountEntry.photo_profile = res.length > 0 ? res[0] : null)
      )
    );
    // Upload patient INE
    promises.push(
      uploadFiles(
        `${STORAGE.patient_files}/${key}`,
        data[ID.file_upload_idcard_patient]
      ).then((res) => (patientEntry.photo_ine = res.length > 0 ? res[0] : null))
    );
    // Upload contact person INE
    promises.push(
      uploadFiles(
        `${STORAGE.patient_files}/${key}`,
        data[ID.file_upload_idcard_contact]
      ).then(
        (res) =>
          (patientEntry.contact_person.photo_ine =
            res.length > 0 ? res[0] : null)
      )
    );
    // Upload service proof of deposit
    promises.push(
      uploadFiles(
        `${STORAGE.patient_files}/${key}`,
        data[ID.file_upload_proof_of_deposit]
      ).then(
        (res) =>
          (patientEntry.service.photo_of_deposit =
            res.length > 0 ? res[0] : null)
      )
    );
    // Upload contract
    promises.push(
      uploadFiles(
        `${STORAGE.patient_files}/${key}`,
        data[ID.file_upload_contract]
      ).then(
        (res) =>
          (patientEntry.service.contract = res.length > 0 ? res[0] : null)
      )
    );
    await Promise.all(promises);
  };

  try {
    switch (actionName) {
      case ID.insert:
        // If user registered himself
        if (data.is_register === true) {
          yield put(actions.setModal(ID.register_modal));
        }

        const newFirebaseId = getDatabaseNewDocumentRef(DB.accounts).id;

        const functionParams: IWriteAuthAccountParams = {
          uid: newFirebaseId,
          email: accountEntry.email,
          name_first: accountEntry.name_first,
          name_last: accountEntry.name_last,
          role: accountEntry.role,
          approved: data.approved,
          send_access: data.send_access,
          action: actionName,
        };

        // @ts-ignore
        const res = yield callCloudFunction(
          SPACE_FUNCTIONS.gl_write_auth_account,
          functionParams
        );

        // If the email is already in use, this throws
        if (res.data.error != null) {
          throw { code: res.data.error };
        }

        // If user regisered himself, login so we can write to DB and Storage
        if (data.is_register === true) {
          yield firebaseLogin(SignInMethod.EMAIL_PASSWORD, {
            email: data.email,
            password: res.data.password,
          });
        }

        // Upload files. Note: needs to be after Cloud Function as the email might be in use already.
        yield uploadAllFiles(newFirebaseId);

        // @ts-ignore
        patientEntry.time_last_update = serverTimestamp();

        // set ACCOUNTS entry
        yield setDatabaseDocument(
          `${DB.accounts}/${newFirebaseId}`,
          accountEntry
        );

        // set PATIENTS entry
        const { last_history_entries, ...patientEntryFinal } = patientEntry;
        yield setDatabaseDocument(
          `${DB.patients}/${newFirebaseId}`,
          patientEntryFinal
        );

        // set PATIENT history entry
        if (data[ID.diagnosis] != null && data[ID.status] != null) {
          yield addDatabaseDocument(
            `${DB.patients}/${newFirebaseId}/${DB.sub_history}`,
            // @ts-ignore
            yield getNewHistoryRecord(
              newFirebaseId,
              data[ID.status],
              data[ID.diagnosis],
              data[ID.note],
              data[ID.file_upload],
              rx_userAccount?.role ?? ACCOUNT_ROLE.patient.value,
              rx_userAccount?.name_first ?? accountEntry.name_first,
              rx_userAccount?.name_last ?? accountEntry.name_last,
              timestamp
            )
          );
        }

        // If user regisered himself, logout
        if (data.is_register === true) {
          // Sept 2021: Needed because otherwise Firestore throws an error for logging out too fast after DB write (just an assumption... test again in a year or so)
          yield sleep(1);
          // Logout
          yield firebaseLogout();
        }

        // if it's a patient with account,
        // show the generated password,
        // else: just close the modal
        if (accountEntry.approved === ACCOUNT_APPROVED.with_account.value) {
          yield put(actions.accountAction.success(res.data.password));
        } else if (data.is_register !== true) {
          yield put(actions.setModal(ID.none_reset));
        }

        yield put(actions.patientAction.success());
        showMessage('message.patientAdded', ID.success);
        break;

      case ID.update:
        // check if the email was changed,
        // if so, we call the `gl_update_auth_email` cloud function
        // @ts-ignore
        const accountDoc = yield getDatabaseDocument(`${DB.accounts}/${key}`);
        if (accountEntry.email !== accountDoc.data().email) {
          const functionParams: IUpdateAuthEmailParams = {
            uid: key,
            email: accountEntry.email,
          };
          // @ts-ignore
          const res = yield callCloudFunction(
            SPACE_FUNCTIONS.gl_update_auth_email,
            functionParams
          );

          if (res.data.error != null) {
            throw { code: res.data.error };
          }
        }

        // Upload files. Note: needs to be after Cloud Function as the email might be in use already.
        yield uploadAllFiles(key);

        // update ACCOUNTS entry
        // Note: for now (v1.1.0) we don't have "approved" in editModal, so don't update it
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { approved, ...filteredAccountEntry } = accountEntry;
        yield updateDatabaseDocument(
          `${DB.accounts}/${key}`,
          filteredAccountEntry
        );

        // update PATIENTS entry
        // Note: we don't update a few fields
        const {
          // history,
          time_last_update,
          sheets_with_data,
          ...filteredPatientEntry
        } = patientEntry;
        yield updateDatabaseDocument(
          `${DB.patients}/${key}`,
          filteredPatientEntry
        );

        showMessage('message.patientUpdated', ID.success);
        yield put(actions.patientAction.success());
        yield put(actions.setModal(ID.none_reset));
        break;

      case ID.delete:
        yield deleteDatabaseDocument(`${DB.patients}/${key}`);
        yield deleteDatabaseDocument(`${DB.accounts}/${key}`);
        showMessage('message.patientDeleted', ID.success);
        yield put(actions.patientAction.success());
        break;

      case ID.insert_record:
        yield addDatabaseDocument(
          `${DB.patients}/${key}/${DB.sub_history}`,
          // @ts-ignore
          yield getNewHistoryRecord(
            key,
            data[ID.status],
            data[ID.diagnosis],
            data[ID.note],
            data[ID.file_upload],
            rx_userAccount?.role ?? ACCOUNT_ROLE.patient.value,
            rx_userAccount?.name_first ?? accountEntry.name_first,
            rx_userAccount?.name_last ?? accountEntry.name_last,
            timestamp
          )
        );
        // Refresh patient history
        yield put(
          actions.refreshPatientHistory.trigger({
            keyPatient: key,
            quantity: fetchHistoryLength,
          })
        );
        // Return
        showMessage('message.patientUpdated', ID.success);
        yield put(actions.patientAction.success());
        yield put(actions.setModal(ID.none_reset));
        break;

      case ID.update_record:
        if (payload.record == null) {
          throw new Error('Record cannot be undefined or empty!');
        }
        yield updateDatabaseDocument(
          `${DB.patients}/${key}/${DB.sub_history}/${payload.record.key}`,
          // @ts-ignore
          yield getUpdatedHistoryRecord(key, data, payload.record)
        );
        // Refresh patient history
        yield put(
          actions.refreshPatientHistory.trigger({
            keyPatient: key,
            quantity: fetchHistoryLength,
          })
        );
        showMessage('message.patientUpdated', ID.success);
        yield put(actions.patientAction.success());
        break;

      case ID.delete_record:
        yield deleteDatabaseDocument(
          `${DB.patients}/${key}/${DB.sub_history}/${data.recordKey}`
        );
        // Refresh patient history
        yield put(
          actions.refreshPatientHistory.trigger({
            keyPatient: key,
            quantity: fetchHistoryLength,
          })
        );
        showMessage('message.recordDeleted', ID.success);
        yield put(actions.patientAction.success());
        break;

      default:
        throw new Error(
          `User: ${rx_userAccount.key} - Payload: ${JSON.stringify(payload)}`
        );
    }
  } catch (error) {
    const err = error as AuthError;
    if (
      err?.code != null &&
      [ERROR_CODE.auth_email_exists, ERROR_CODE.auth_email_already_in_use].some(
        (e) => e === err.code
      )
    ) {
      showMessage('auth.emailExists', ID.error);
    } else {
      ReportingFE.Error(error, ['patientAction']);
      showMessage('error.generalError', ID.error);
    }
    yield put(actions.patientAction.failure(error));
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.patientAction.fulfill());
    // fullfill the user action only when an approved account was created
    // this makes sure that the modal resets successfully
    if (accountEntry.approved === ACCOUNT_APPROVED.with_account.value) {
      yield put(actions.accountAction.fulfill());
    }
  }
}

function* refreshPatientHistory({ payload }: any) {
  yield put(actions.refreshPatientHistory.request());
  const { keyPatient, quantity } = payload;

  try {
    const queryConstraints: QueryConstraint[] = [
      orderBy(ID.time_created, 'desc'),
      limit(quantity),
    ];

    // @ts-ignore
    const lastHistoryEntry = yield getDocsByQuery(
      query(
        getDatabaseCollectionRef(
          `${DB.patients}/${keyPatient}/${DB.sub_history}`
        ),
        ...queryConstraints
      )
    );
    const newHistory = snapshotAsArray(lastHistoryEntry, true);
    yield put(
      actions.refreshPatientHistory.success({
        key: keyPatient,
        data: newHistory,
        replaceAll: quantity > 3,
      })
    );
  } catch (error) {
    ReportingFE.Error(error, ['refreshPatientHistory']);
    showMessage('error.generalError', ID.error);
    yield put(actions.refreshPatientHistory.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.refreshPatientHistory.fulfill());
  }
}

function* patientStaffArrayAction({ payload }: any) {
  yield put(actions.patientStaffArrayAction.request());
  const { actionName, keyPatient, fieldId, keyStaff } = payload;
  // @ts-ignore
  const rx_userAccount = yield select(getUserAccount);

  try {
    switch (actionName) {
      case ID.insert:
        yield updateDatabaseDocument(`${DB.patients}/${keyPatient}`, {
          [fieldId]: arrayUnion(keyStaff),
        });
        break;

      case ID.delete:
        yield updateDatabaseDocument(`${DB.patients}/${keyPatient}`, {
          [fieldId]: arrayRemove(keyStaff),
        });
        break;

      default:
        throw new Error(
          `User: ${rx_userAccount.key} - Payload: ${JSON.stringify(payload)}`
        );
    }
    showMessage('message.patientUpdated', ID.success);
    yield put(actions.patientStaffArrayAction.success());
  } catch (error) {
    ReportingFE.Error(error, ['patientStaffArrayAction']);
    showMessage('error.generalError', ID.error);
    yield put(actions.patientStaffArrayAction.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.patientStaffArrayAction.fulfill());
  }
}

function* inventoryAction({ payload }: any) {
  yield put(actions.inventoryAction.request());
  const { actionName, keyPatient, keyDoc, data } = payload;
  // @ts-ignore
  const rx_userAccount = yield select(getUserAccount);

  try {
    switch (actionName) {
      case ID.insert:
        yield addDatabaseDocument(
          `${DB.patients}/${keyPatient}/${DB.sub_inventory}`,
          data
        );
        showMessage('message.articleAdded', ID.success);
        break;

      case ID.update:
        yield updateDatabaseDocument(
          `${DB.patients}/${keyPatient}/${DB.sub_inventory}/${keyDoc}`,
          data
        );
        showMessage('message.articleUpdated', ID.success);
        break;

      case ID.delete:
        yield deleteDatabaseDocument(
          `${DB.patients}/${keyPatient}/${DB.sub_inventory}/${keyDoc}`
        );
        showMessage('message.articleDeleted', ID.success);
        break;

      default:
        throw new Error(
          `User: ${rx_userAccount.key} - Payload: ${JSON.stringify(payload)}`
        );
    }
    yield put(actions.inventoryAction.success());
    yield put(actions.setModal(ID.none_reset));
  } catch (error) {
    ReportingFE.Error(error, ['inventoryAction']);
    showMessage('error.generalError', ID.error);
    yield put(actions.inventoryAction.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.inventoryAction.fulfill());
  }
}

function* updatePatientSheet(
  action: ReturnType<typeof actions.updatePatientSheet.trigger>
) {
  yield put(actions.updatePatientSheet.request());
  const { keyPatient, keySheet, path, data } = action.payload;
  try {
    yield updateDatabaseDocument(
      `${DB.patients}/${keyPatient}/${DB.sub_sheets}/${keySheet}`,
      { [path.join('.')]: data }
    );
    showMessage('global.databaseUpdated', ID.success, '', 1);
  } catch (error) {
    ReportingFE.Error(error, ['updatePatientSheet']);
    showMessage('error.generalError', ID.error);
    yield put(actions.updatePatientSheet.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.updatePatientSheet.fulfill());
  }
}

function* getPatientHistory(
  action: ReturnType<typeof actions.getPatientHistory.trigger>
) {
  yield put(actions.getPatientHistory.request());
  const { keyPatient, dateEarly, dateLate } = action.payload;
  try {
    // @ts-ignore
    const entries = yield getDocsByQuery(
      query(
        getDatabaseCollectionRef(
          `${DB.patients}/${keyPatient}/${DB.sub_history}`
        ),
        where(ID.time_created, '>', dateEarly),
        where(ID.time_created, '<', dateLate),
        orderBy(ID.time_created, 'desc')
      )
    );
    const res = snapshotAsArray(entries, true);
    yield put(
      actions.getPatientHistory.success({
        key: keyPatient,
        data: res,
      })
    );
  } catch (error) {
    ReportingFE.Error(error, ['getPatientHistory']);
    showMessage('error.generalError', ID.error);
    yield put(actions.getPatientHistory.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.getPatientHistory.fulfill());
  }
}

function* setLoginTimestamp(
  action: ReturnType<typeof actions.setLoginTimestamp.trigger>
) {
  yield put(actions.setLoginTimestamp.request());
  const { uid } = action.payload;
  try {
    yield updateDatabaseDocument(`${DB.accounts}/${uid}`, {
      [ID.time_last_login]: serverTimestamp(),
    });
    yield put(actions.setLoginTimestamp.success());
  } catch (error) {
    ReportingFE.Error(error, ['setLoginTimestamp']);
    showMessage('error.generalError', ID.error);
    yield put(actions.setLoginTimestamp.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.setLoginTimestamp.fulfill());
  }
}

function* sendActivityNotification(
  action: ReturnType<typeof actions.sendActivityNotification.trigger>
) {
  yield put(actions.sendActivityNotification.request());
  const { emails, patient, message } = action.payload;
  try {
    const functionParams: ISendActivityNotification = {
      emails,
      patient,
      message,
    };
    // @ts-ignore
    const res = yield callCloudFunction(
      SPACE_FUNCTIONS.gl_send_activity_notification,
      functionParams
    );

    if (res.data.error != null) {
      throw { code: res.data.error };
    }
    yield put(actions.sendActivityNotification.success());
  } catch (error) {
    ReportingFE.Error(error, ['sendActivityNotification']);
    showMessage('error.generalError', ID.error);
    yield put(actions.sendActivityNotification.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.sendActivityNotification.fulfill());
  }
}

function* sendOnboardingNotification(
  action: ReturnType<typeof actions.sendOnboardingNotification.trigger>
) {
  yield put(actions.sendOnboardingNotification.request());
  const { emails, patient, message } = action.payload;
  try {
    const functionParams: ISendOnboardingNotification = {
      emails,
      patient,
      message,
    };
    // @ts-ignore
    const res = yield callCloudFunction(
      SPACE_FUNCTIONS.gl_send_onboarding_notification,
      functionParams
    );

    if (res.data.error != null) {
      throw { code: res.data.error };
    }
    showMessage('message.onboardingNotificationSent', ID.success);
    yield put(actions.sendOnboardingNotification.success());
  } catch (error) {
    ReportingFE.Error(error, ['sendOnboardingNotification']);
    showMessage('error.generalError', ID.error);
    yield put(actions.sendOnboardingNotification.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.sendOnboardingNotification.fulfill());
  }
}

function* getPdfPatientPage(
  action: ReturnType<typeof actions.getPdfPatientPage.trigger>
) {
  yield put(actions.getPdfPatientPage.request());
  try {
    showMessage('message.pdfCreating', ID.loading, ID.key);
    const res: AxiosResponse<Blob> = yield axios({
      method: 'POST',
      url: getCloudFunctionUrl(SPACE_FUNCTIONS.gl_pdf_patient_page),
      data: action.payload,
      responseType: 'blob',
      timeout: 30000,
    });
    let filename: string = decodeURIComponent(res.headers['pdf-title'] ?? '');
    if (!filename) {
      ReportingFE.Error(
        new Error(
          `PDF filename not set correctly for patient ${action.payload.key_patient} and sheet ${action.payload.key_sheet}.`
        )
      );
      filename = 'result.pdf';
    }
    yield put(
      actions.getPdfPatientPage.success({
        data: res.data,
        filename: filename,
      } as TPdfResultBlob)
    );
    showMessage('message.pdfCreated', ID.success, ID.key, MessageDuration.long);
  } catch (error) {
    ReportingFE.Error(error, ['getPdfPatientPage']);
    showMessage('error.generalError', ID.error, ID.key);
    yield put(actions.getPdfPatientPage.failure());
  } finally {
    // Needed so that reducer values update correctly.
    yield sleep(1);
    yield put(actions.getPdfPatientPage.fulfill());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.subscribeAccounts.TRIGGER, subscribeAccounts),
    takeEvery(actions.subscribePatients.TRIGGER, subscribePatients),
    takeEvery(actions.subscribePatientSheet.TRIGGER, subscribePatientSheet),
    takeEvery(
      actions.subscribePatientInventory.TRIGGER,
      subscribePatientInventory
    ),
    takeEvery(actions.accountAction.TRIGGER, accountAction),
    takeEvery(actions.patientAction.TRIGGER, patientAction),
    takeEvery(actions.patientStaffArrayAction.TRIGGER, patientStaffArrayAction),
    takeEvery(actions.inventoryAction.TRIGGER, inventoryAction),
    takeEvery(actions.updatePatientSheet.TRIGGER, updatePatientSheet),
    takeEvery(actions.refreshPatientHistory.TRIGGER, refreshPatientHistory),
    takeEvery(actions.getPatientHistory.TRIGGER, getPatientHistory),
    takeEvery(actions.setLoginTimestamp.TRIGGER, setLoginTimestamp),
    takeEvery(
      actions.sendActivityNotification.TRIGGER,
      sendActivityNotification
    ),
    takeEvery(
      actions.sendOnboardingNotification.TRIGGER,
      sendOnboardingNotification
    ),
    takeEvery(actions.getPdfPatientPage.TRIGGER, getPdfPatientPage),
  ]);
}

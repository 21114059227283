import * as Sentry from '@sentry/react';
import actions from 'api/redux/actions';
import { TGL_Auth, TReducerProps } from 'api/redux/types';
import _ from 'lodash';

const initState: TGL_Auth = {
  rx_userAccount: null,
  rx_refreshRouter: 0,
};

export default function authReducer(
  state = initState,
  { type, payload }: TReducerProps
): Record<string, unknown> {
  switch (type) {
    // SET REFRESH ROUTER
    case actions.setRefreshRouter.TRIGGER:
      return { ...state, rx_refreshRouter: state.rx_refreshRouter + 1 };

    // SET USER ACCOUNT
    case actions.setUserAccount.TRIGGER:
      if (_.isEmpty(payload)) {
        Sentry.configureScope((scope) => scope.setUser(null));
      } else {
        Sentry.setUser({
          email: payload.email,
          username: `${payload.name_first} ${payload.name_last}`,
        });
      }
      return { ...state, rx_userAccount: payload };
    default:
      return state;
  }
}

import { color, size } from '@busybytes/style-constants';
import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledFooter = styled(Layout.Footer)`
  font-size: 13px;
  background: #fff;
  text-align: center;
  border-top: 1px solid ${color.border.border_light};
  line-height: 1.2em;
  padding: 10px 20px 8px 20px;
  @media ${size.max_sm} {
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
  }
  a {
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    margin: 0;
    margin-top: 3px;
    font-size: 9px;
    opacity: 0.8;
    line-height: 14px;
  }
`;

export const projectStyles = {
  // Standard
  COLOR_PRIMARY: '#40a9ff',
  COLOR_PRIMARY_BACKGROUND: '#e6f7ff',
  COLOR_SECONDARY: '#9aa1b0',
  COLOR_BACKGROUND_MAIN: '#f8f9fe',
  COLOR_BACKGROUND_ARCHIVED: '#f7f8f9',

  // Fonts
  FONT_PRIMARY_FAMILY: 'inherit',
  FONT_MAIN_SIZE: 'inherit',
  FONT_COLOR_MAIN: '#777777',
  FONT_COLOR_LIGHT: '#999999',
  FONT_COLOR_DARK: '#555555',
  FONT_COLOR_BLACKISH: '#333333',

  // Tags
  COLOR_TAG_ARCHIVED: '#888888',

  // Specific
  COLOR_CARD_BLUE: '#4285F4',
  COLOR_CARD_CYAN: '#33B5E5',
  COLOR_CARD_EMERALD: '#20b2aa',
  COLOR_BUTTON_GRAYBLUE: '#5e85b4',

  SUCCESS: '#3fd03f',

  PATIENT_SHEET_SKY_GRAD_LIGHT: '#ceefff',
  PATIENT_SHEET_SKY_GRAD_DARK: '#b0ddf6',
  PATIENT_SHEET_SKY_TITLE: '#004973',
  PATIENT_SHEET_BLUE_GRAD_LIGHT: '#cad4ff',
  PATIENT_SHEET_BLUE_GRAD_DARK: '#a4bbfc',
  PATIENT_SHEET_BLUE_TITLE: '#002982',
  PATIENT_SHEET_GREEN_GRAD_LIGHT: '#dfffca',
  PATIENT_SHEET_GREEN_GRAD_DARK: '#caf1b7',
  PATIENT_SHEET_GREEN_TITLE: '#267500',
  PATIENT_SHEET_YELLOW_GRAD_LIGHT: '#fff7ca',
  PATIENT_SHEET_YELLOW_GRAD_DARK: '#fcefa4',
  PATIENT_SHEET_YELLOW_TITLE: '#827000',
  PATIENT_SHEET_RED_GRAD_LIGHT: '#ffd2e0',
  PATIENT_SHEET_RED_GRAD_DARK: '#fcb6ca',
  PATIENT_SHEET_RED_TITLE: '#750021',
  PATIENT_SHEET_PURPLE_GRAD_LIGHT: '#edd2ff',
  PATIENT_SHEET_PURPLE_GRAD_DARK: '#d7b6fc',
  PATIENT_SHEET_PURPLE_TITLE: '#3a007c',

  // Can be deleted soon
  COLOR_IMMOAPP: '#087e87',
  COLOR_IMMOFONDS: '#008cff',
  COLOR_IMMOMOVE: '#f12e45',
};

import { color } from '@busybytes/style-constants';
import { createGlobalStyle } from 'styled-components';
import { projectStyles } from './projectStyles';

export default createGlobalStyle`

  // ---------------------
  //       NORMALIZE 
  // ---------------------

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  // ---------------------
  //   HTML2CANVAS HELPER 
  // ---------------------
  
  .hide-scrollbar {
    overflow: -moz-hidden-unscrollable;
    overflow: hidden;
  }

  // ---------------------
  //   ANTD IMPROVEMENTS 
  // ---------------------

  // Tabs have very little space, add some extra (4.5.0)
  .ant-tabs-nav .ant-tabs-tab {
    padding: 12px 16px;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom-color: ${color.border.border_default}
  }
  td.ant-table-cell-ellipsis > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-popover {
    max-width: 400px;
  }
  .ant-typography {
    &.prewrap {
      white-space: pre-wrap;
    }
    &.light {
      color: ${projectStyles.FONT_COLOR_LIGHT};
      font-weight: 400;
      font-size: 12px;
    }
  }

  // ---------------------
  //     ANTD BUGFIXES 
  // ---------------------

  // This is caused because of modal "forceRender". Check again if solved in a few months. [Toeay: Sept, 2020]
  .ant-modal-footer .ant-btn-loading .ant-btn-loading-icon {
    width: inherit !important;
  }

  // The Tailwind "reset styles" assign all <img> tags  "disply: block" which doesn't work well with ant-image-preview-modal.
  // We reset it to the default "display: inline"
  .ant-image-preview-img {
    display: inline;
  }

  // We hide all extra buttons of antd preview modal as they cause troubles with table onRow click handler
  .ant-image-preview-operations {
    li:not(:first-child) {
      display: none;
    }
  }

  // ---------------------
  //     TEMP LIBRARY
  // ---------------------

  .bb-line-height-none {
    line-height: 1 !important;
  }
  .bb-line-height-tight {
    line-height: 1.25 !important;
  }
  .bb-line-height-snug {
    line-height: 1.375 !important;
  }
  .bb-line-height-normal {
    line-height: 1.5 !important;
  }
 
`;

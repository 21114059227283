import antdEs from 'antd/lib/locale-provider/es_ES';
import esMessages from 'functions/src/i18n/es.json';
import moment from 'moment';
import 'moment/locale/es';

const esLang = {
  messages: {
    ...esMessages,
  },
  antd: antdEs,
  localeShort: 'es',
  localeHtml: 'es-MX',
  localeReadable: 'Español',
  initMoment: (): string => {
    moment.updateLocale('es', {
      week: {
        dow: 1,
      },
    });
    return moment.locale('es');
  },
};
export default esLang;

import delang from 'assets/i18n/setup/de_DE';
import enlang from 'assets/i18n/setup/en_US';
import esLang from 'assets/i18n/setup/es_MX';
import { TLangIds } from 'functions/shared/types';

export const AppLocale = {
  en: enlang,
  de: delang,
  es: esLang,
};

export const defaultLanguage = (process.env.REACT_APP_PLATFORM_LANG ??
  'en') as TLangIds;

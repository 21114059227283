import { intl, IntlMessages } from 'components/Utility/IntlMessages';
import moment from 'moment';
import React from 'react';
import packageJson from '../../../../package.json';
import { StyledFooter } from './Footer.styles';

export default (): JSX.Element => {
  return (
    <StyledFooter>
      {'©'}
      {moment().year()}{' '}
      <a
        href={intl.formatMessage({ id: 'link.busybytes.web' })}
        target="_blank"
        rel="noopener noreferrer"
      >
        BusyBytes GmbH
      </a>{' '}
      &ndash; <IntlMessages id="menu.allRightsReserved" />
      <p>
        (<IntlMessages id="menu.version" /> {packageJson.version})
      </p>
    </StyledFooter>
  );
};

import antdEn from 'antd/lib/locale-provider/en_US';
import enMessages from 'functions/src/i18n/en.json';
import moment from 'moment';

const enLang = {
  messages: {
    ...enMessages,
  },
  antd: antdEn,
  localeShort: 'en',
  localeHtml: 'en-US',
  localeReadable: 'English',
  initMoment: (): string => {
    moment.updateLocale('en', {
      week: {
        dow: 1,
      },
    });
    return moment.locale('en');
  },
};
export default enLang;

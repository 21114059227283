import antdDE from 'antd/lib/locale-provider/de_DE';
import deMessages from 'functions/src/i18n/de.json';
import moment from 'moment';
import 'moment/locale/de';

const deLang = {
  messages: {
    ...deMessages,
  },
  antd: antdDE,
  localeShort: 'de',
  localeHtml: 'de-DE',
  localeReadable: 'Deutsch',
  initMoment: (): string => {
    moment.updateLocale('de', {
      week: {
        dow: 1,
      },
    });
    return moment.locale('de');
  },
};

export default deLang;

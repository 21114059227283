import { FileExtensions } from 'assets/definitions/AppConstants';
import { FirebaseError, initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  SignInMethod,
  signInWithEmailAndPassword,
  updatePassword,
} from 'firebase/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  initializeFirestore,
  Query,
  QuerySnapshot,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import {
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { ERROR_CODE, ID } from 'functions/shared/constants';
import { IStorageFile } from 'functions/shared/types';
// import 'firebase/remote-config';
import { ReportingFE } from 'library/sentry';
import { getThumbPath } from 'library/snippets/file';
import { sleep } from 'library/snippets/general';
import { sorterHelper } from 'library/snippets/ui_ux';

// -------------------------------
//              INIT
// -------------------------------

const config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
};
const firebaseApp = initializeApp(config);
const storage = getStorage(firebaseApp);
const auth = getAuth(firebaseApp);
const functions = getFunctions(
  firebaseApp,
  process.env.REACT_APP_CLOUD_FUNCTION_REGION
);

let database: Firestore;
if (process.env.REACT_APP_FB_USE_EMULATOR) {
  connectFunctionsEmulator(functions, window.location.hostname, 5003);
  // Note: currently Firestore emulator has problems with Storage rules. Just edit the local file in the main folder and allow all read and writes. [Sept, 2021]
  database = initializeFirestore(firebaseApp, {
    host: `${window.location.hostname}:8080`,
    ssl: false,
  });
} else {
  database = getFirestore(firebaseApp);
}

// -------------------------------
//            DATABASE
// -------------------------------

export const getDatabaseDocumentRef = (path: string) => {
  return doc(database, path);
};

export const getDatabaseNewDocumentRef = (path: string) => {
  return doc(collection(database, path));
};

export const getDatabaseDocument = async (path: string) => {
  return getDoc(doc(database, path));
};

export const addDatabaseDocument = async (path: string, data: any) => {
  return addDoc(collection(database, path), data);
};

export const setDatabaseDocument = async (path: string, data: any) => {
  return setDoc(doc(database, path), data);
};

export const updateDatabaseDocument = async (path: string, data: any) => {
  return updateDoc(doc(database, path), data);
};

export const deleteDatabaseDocument = async (path: string) => {
  return deleteDoc(doc(database, path));
};

export const getDatabaseCollectionRef = (path: string) => {
  return collection(database, path);
};

export const getDatabaseCollection = async (path: string) => {
  return getDocs(collection(database, path));
};

export const getDocsByQuery = async (query: Query) => {
  return getDocs(query);
};

export const snapshotAsObject = (snapshot: QuerySnapshot) => {
  const data: any = {};
  snapshot.forEach((doc: DocumentData) => {
    data[doc.id] = doc.data();
  });
  return data;
};

export const snapshotAsArray = (
  snapshot: QuerySnapshot,
  withKeyField: boolean,
  sortedByKey: string | undefined = undefined,
  sortedReverse = false
) => {
  let res;
  if (withKeyField) {
    res = snapshot.docs.map((doc: DocumentData) => ({
      [ID.key]: doc.id,
      ...doc.data(),
    }));
  } else {
    res = snapshot.docs.map((doc: DocumentData) => doc.data());
  }
  if (sortedByKey != null) {
    res = res.sort((a: DocumentData, b: DocumentData) =>
      sorterHelper(a, b, sortedByKey, sortedReverse)
    );
  }
  return res;
};

// -------------------------------
//              AUTH
// -------------------------------

export const firebaseLogin = async (provider: string, data: any) => {
  await setPersistence(
    auth,
    data.remember_me ? browserLocalPersistence : browserSessionPersistence
  );
  switch (provider) {
    case SignInMethod.EMAIL_PASSWORD:
      return signInWithEmailAndPassword(auth, data.email, data.password);
    // case this.FACEBOOK:
    //   return FacebookAuthProvider();
    // case this.GOOGLE:
    //   return GoogleAuthProvider();
    // case this.GITHUB:
    //   return GithubAuthProvider();
    // case this.TWITTER:
    //   return TwitterAuthProvider();
    default:
      break;
  }
};

export const firebaseLogout = async () => {
  return auth.signOut();
};

export const getFirebaseAuth = () => {
  return auth;
};

export const sendVerificationEmail = async () => {
  return auth.currentUser != null
    ? sendEmailVerification(auth.currentUser)
    : undefined;
};

export const getCurrentUser = () => {
  return auth.currentUser;
};

export const reauthenticateWithCredentialHelper = async (
  email: string,
  password: string
) => {
  if (auth.currentUser == null) {
    return Promise.reject();
  }
  return reauthenticateWithCredential(
    auth.currentUser,
    EmailAuthProvider.credential(email, password)
  );
};

export const updatePasswordHelper = async (password: string) => {
  if (auth.currentUser == null) {
    return Promise.reject();
  }
  return updatePassword(auth.currentUser, password);
};

export const getAuthToken = async () => {
  try {
    return auth.currentUser?.getIdToken();
  } catch (err) {
    ReportingFE.Error(err, ['getAuthToken']);
    return Promise.reject();
  }
};

export const resetPassword = async (email: string) => {
  return sendPasswordResetEmail(auth, email);
};

export const setLanguageCode = (code: string) => {
  if (auth != null) {
    auth.languageCode = code;
  }
};

// -------------------------------
//             STORAGE
// -------------------------------

// Returns mainUrl and thumbUrl for images
export const getFilesWithDownloadURLs = async (files: string[]) => {
  const promises: Promise<IStorageFile>[] = [];
  files.forEach((file: string) => {
    promises.push(loadSingleFile(file));
  });
  const res = await Promise.all(promises);
  return res.filter((f) => f != null);
};

// enum FETCH_TYPE {
//   onlyThumb,
//   onlyMain,
//   thumbAndMain,
// }

export const loadSingleFile = async (
  path: string
  // fetchType: FETCH_TYPE = FETCH_TYPE.thumbAndMain
): Promise<IStorageFile> => {
  const pathExt = path.split('.').pop();
  const fileName = path.split('/').pop();
  const thumbPath = getThumbPath(path);
  const res: IStorageFile = {
    name: fileName ?? '-',
    path: path,
  };
  if (pathExt != null && FileExtensions.images.includes(pathExt)) {
    // Since we are optimizing the images directly after upload,
    // we have a race condition and need to poll here accordingly.
    // Max 20 secs.
    for (let x = 0; x < 20; x++) {
      try {
        const metadata = await getMetadata(ref(storage, path));
        if (metadata.customMetadata?.optimized != null) {
          // Note: metadata.customMetadata.optimized is of type string
          break;
        }
        await sleep(1000);
      } catch (error) {
        const fbError = error as FirebaseError;
        if (fbError.code !== ERROR_CODE.storage_object_not_found) {
          ReportingFE.Error(error, ['loadSingleFile1']);
        }
        break;
      }
    }
    // if([FETCH_TYPE.onlyMain, FETCH_TYPE.thumbAndMain].some((f) => fetchType === f)){
    try {
      res.mainUrl = await getDownloadURL(ref(storage, path));
    } catch (error) {
      const fbError = error as FirebaseError;
      if (fbError.code !== ERROR_CODE.storage_object_not_found) {
        ReportingFE.Error(error, ['loadSingleFile2']);
      }
    }
    // }

    // Use 2 try / catch so that if thumb fails,
    // we still get the main file url
    // if([FETCH_TYPE.onlyThumb, FETCH_TYPE.thumbAndMain].some((f) => fetchType === f)){
    try {
      res.thumbUrl = await getDownloadURL(ref(storage, thumbPath));
    } catch (error) {
      const fbError = error as FirebaseError;
      if (fbError.code !== ERROR_CODE.storage_object_not_found) {
        console.warn('Thumb not loaded.');
        ReportingFE.Error(error, ['loadSingleFile3']);
      }
      res.thumbUrl = res.mainUrl;
    }
    // }
  } else {
    // Not an image. Return just main url.
    try {
      // We need thumbUrl for previewModal to work correctly
      const url = await getDownloadURL(ref(storage, path));
      res.mainUrl = url;
      res.thumbUrl = url;
    } catch (error) {
      const fbError = error as FirebaseError;
      if (fbError.code !== ERROR_CODE.storage_object_not_found) {
        ReportingFE.Error(error, ['loadSingleFile4']);
      }
    }
  }
  return res;
};

// Returns the file name and storage path
const uploadFilePromise = async (
  base: string,
  fileName: string,
  file: any
): Promise<string> => {
  // If a file is already uploaded,
  // originFileObj does not exist
  if (file == null) {
    return `${base}/${fileName}`;
  }
  try {
    await uploadBytes(ref(storage, `${base}/${fileName}`), file);
    return `${base}/${fileName}`;
  } catch (err) {
    ReportingFE.Error(err, ['uploadFilePromise']);
    return Promise.reject();
  }
};

export const fileUpload = async (
  storagePath: string,
  filesArray: any[]
): Promise<string[]> => {
  const promises: Promise<string>[] = [];
  filesArray.forEach(async (file) => {
    promises.push(
      uploadFilePromise(storagePath, file.name, file.originFileObj)
    );
  });
  return Promise.all(promises);
};

// -------------------------------
//             FUNCTIONS
// -------------------------------

export const callCloudFunction = async (name: string, parameters?: any) => {
  return httpsCallable(functions, name)(parameters);
};

/**
 * Get the Cloud Function URL based on its name and the environment.
 */
export const getCloudFunctionUrl = (functionName: string): string => {
  if (process.env.REACT_APP_FB_USE_EMULATOR) {
    return `http://localhost:5003/${process.env.REACT_APP_FB_PROJECT_ID}/${process.env.REACT_APP_CLOUD_FUNCTION_REGION}/${functionName}`;
  }
  return `https://${process.env.REACT_APP_CLOUD_FUNCTION_REGION}-${process.env.REACT_APP_FB_PROJECT_ID}.cloudfunctions.net/${functionName}`;
};

import { blue, red } from '@ant-design/colors';
import { color, size } from '@busybytes/style-constants';
import { Collapse, Form, Table, Tag } from 'antd';
import { projectStyles } from 'assets/styles/projectStyles';
import { ID } from 'functions/shared/constants';
import styled from 'styled-components';

export const PageTitle = styled.div`
  margin-bottom: 30px;

  // added by Santiago
  display: flex;
  justify-content: space-between;
  align-items: center;
  // end

  &.margin-small {
    margin-bottom: 15px;
  }
  &.margin-0 {
    margin-bottom: 0;
  }
  .hoverable-title {
    cursor: pointer;
    display: inline-block;
    .ant-page-header-back-button,
    .ant-page-header-heading-title {
      transition: color 0.3s;
    }
    &:hover {
      .ant-page-header-back-button,
      .ant-page-header-heading-title {
        color: ${projectStyles.COLOR_PRIMARY};
      }
    }
  }
  .ant-page-header {
    padding: 0;
    .ant-page-header-heading {
      .ant-page-header-heading-left {
        flex-wrap: wrap;
        overflow: visible;
      }
      .ant-page-header-heading-title {
        font-weight: 500;
        font-size: 18px;
      }
      .ant-page-header-heading-sub-title {
        overflow: visible;
        flex-basis: 100%;
      }
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  @media ${size.max_sm} {
    margin-bottom: 10px;
  }
  div {
    button {
      @media ${size.max_md} {
        margin-bottom: 0px !important;
      }
    }
  }
  &.margin-half {
    margin-bottom: 20px;
  }
  &.margin-0 {
    margin: 0;
  }
`;

export const TitleHolder = styled.h5`
  margin: 0;
`;

export const TagWithMargin = styled(Tag)`
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

// Better colors and style
export const TagCustom = styled(Tag)`
  font-weight: 500;
  &.single {
    font-family: monospace;
  }
  &:hover {
    opacity: 1;
  }
  ${(props) => {
    // @ts-ignore
    switch (props.customcolor) {
      case 'red':
        return `
          color: ${color.status.red_text};
          background: ${color.status.red_background};
          border: 1px solid ${color.status.red_text}33;
        `;
      case 'yellow':
        return `
          color: ${color.status.yellow_text};
          background: ${color.status.yellow_background};
          border: 1px solid ${color.status.yellow_text}33;
        `;
      case 'orange':
        return `
          color: ${color.status.orange_text};
          background: ${color.status.orange_background};
          border: 1px solid ${color.status.orange_text}33;
        `;
      case 'green':
        return `
          color: ${color.status.green_text};
          background: ${color.status.green_background};
          border: 1px solid ${color.status.green_text}33;
        `;
      case 'cyan':
        return `
          color: ${color.status.cyan_text};
          background: ${color.status.cyan_background};
          border: 1px solid ${color.status.cyan_text}33;
        `;
      case 'blue':
        return `
          color: ${color.status.blue_text};
          background: ${color.status.blue_background};
          border: 1px solid ${color.status.blue_text}33;
        `;
      case 'lightblue':
        return `
          color: ${color.status.lightblue_text};
          background: ${color.status.lightblue_background};
          border: 1px solid ${color.status.lightblue_text}33;
        `;
      case 'purple':
        return `
          color: ${color.status.purple_text};
          background: ${color.status.purple_background};
          border: 1px solid ${color.status.purple_text}33;
        `;
      case 'pink':
        return `
          color: ${color.status.pink_text};
          background: ${color.status.pink_background};
          border: 1px solid ${color.status.pink_text}33;
        `;
      case 'gray':
      default:
        return `
          color: ${color.status.gray_text};
          background: ${color.status.gray_background} !important;
          border: 1px solid ${color.status.gray_text}33;
        `;
    }
  }}
`;

export const IconCell = styled.div`
  display: flex;
  align-items: center;
  .anticon {
    font-size: 18px;
    color: ${blue.primary};
  }
  & > div {
    margin-left: 12px;
  }
`;

// Needed because otherwise an href is required on every element
export const LinkStyle = styled.a`
  ${(props) => {
    switch (props.type) {
      case ID.danger:
        return `
          color: ${red.primary} !important;
          &:hover {
            background-color: ${red[0]} !important;
          }
        `;
      default:
        break;
    }
  }};
`;

// Project specific, not a library component
export const TableCustom = styled(Table).attrs(() => ({
  // this gives a nice shadow on the right
  scroll: { x: true },
}))`
  thead > tr > th {
    font-size: 13px;
    border: 1px solid #ececec;
    border-right: none;
    &:first-child,
    &.no-border-left {
      border-left: none;
    }
    &:before {
      display: none;
    }
  }
  tbody > tr {
    &:not(.no-hover) {
      cursor: pointer;
    }

    background: white;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
      background: inherit;
    }

    &.rowArchived {
      background: ${projectStyles.COLOR_BACKGROUND_ARCHIVED};
      td {
        background-color: inherit;
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 90%;
        &:not(:last-child) {
          opacity: 0.65;
        }
      }
    }
  }
  td,
  th {
    white-space: nowrap;
    &.wrap {
      white-space: inherit;
    }
  }
  td .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TextExtraSmall = styled.div`
  font-size: 12px;
`;

export const InlineTextMedium = styled.span`
  font-weight: 500;
`;

// Todo: can be removed once all forms are using Antd v4
export const Label = styled.label`
  position: relative;
  font-size: 13px;
  line-height: 1.5;
  font-weight: 500;
  padding: 0;
  margin: 0 0 8px;

  &.no-margin {
    margin: 0;
  }
`;

// Todo we need to check what to do with this. I think it is needed for Popups Switches
export const SubLabelText = styled.div`
  color: ${projectStyles.FONT_COLOR_LIGHT};
  font-weight: 400;
  font-size: 12px;
  width: 100%;
  margin: 0 0 8px;

  &.no-margin {
    margin: 0;
  }
  &.triple-margin {
    margin: 0 0 24px;
  }
`;

// Might be in library
export const CustomFormCollapse = styled(Collapse)`
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 0 !important;
    }
    .ant-collapse-content {
      margin-left: -10px;
      margin-right: -10px;
      .ant-collapse-content-box {
        margin: 0;
        padding: 0;
        & > .anticon {
          display: block;
          margin: 8px auto;
        }
        & > div {
          background-color: ${color.gray.bright};
          box-shadow: inset 0px 0px 2px rgb(0 0 0 / 20%);
          padding: 12px 16px;
          @media ${size.max_sm} {
            padding: 10px;
          }
        }
      }
    }
  }
`;

export const FullHeightSpinWrapper = styled.div`
  height: 100%;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
`;

export const CustomOnboardingCollapse = styled(Collapse)`
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 0;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        margin: 0;
        padding: 0;
      }
    }
  }
`;

// Might be in library
export const FormItemSwitch = styled(Form.Item)`
  .ant-row {
    flex-direction: row !important;
  }

  .ant-form-item-label {
    display: flex;
    align-items: center;
    padding: 0;
    label {
      flex-direction: column;
      line-height: 1.5em;
      span {
        width: 100%;
        @media ${size.max_sm} {
          margin-bottom: 4px;
        }
      }
    }
  }
  .ant-form-item-control {
    text-align: right;
    justify-content: center;
  }
  @media ${size.max_sm} {
    .ant-form-item-label {
      flex: 0 0 80% !important;
      max-width: 80% !important;
    }
    .ant-form-item-control {
      flex: 0 0 20% !important;
      max-width: 20% !important;
    }
  }
`;

// Might be in library
export const FilterIconCustom = styled.div`
  font-size: 13px;
  svg {
    stroke: currentColor;
    stroke-width: 3em;
  }
`;

export const SearchFilterWrapper = styled.div`
  input {
    width: 180px;
    margin: 8px;
  }
  > div {
    display: flex;
    justify-content: space-between;
    padding: 0px 8px 7px 3px;
  }
`;

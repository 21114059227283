import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { DividerCustom } from '@busybytes/antd-extensions';
import actions from 'api/redux/actions';
import { TReducerRoot } from 'api/redux/types';
import { PAGE, TOOL } from 'app/router.constants';
import { LinkStyle } from 'assets/styles/globalStyledComponents';
import { IntlMessages } from 'components/Utility/IntlMessages';
import { ACCOUNT_ROLE, ID } from 'functions/shared/constants';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

export default ({ menuPart, onMenuClick }: MenuBuilderProps) => {
  // File members
  const mDispatch = useDispatch();
  const { pathname } = useLocation();

  // Redux state
  const rx_userAccount = useSelector(
    (state: TReducerRoot) => state.GL_Auth.rx_userAccount
  );

  const popoverMenuContent = (
    <>
      <li className={pathname === `/${TOOL.SETTINGS}` ? ID.active : undefined}>
        <Link to={`/${TOOL.SETTINGS}`} onClick={onMenuClick}>
          <SettingOutlined />
          <IntlMessages id="global.settings" />
        </Link>
      </li>
      {menuPart !== 'mobile' && <DividerCustom space={0} />}
      <li>
        <LinkStyle onClick={() => mDispatch(actions.logout())} type={ID.danger}>
          <LogoutOutlined />
          <IntlMessages id="menu.logout" />
        </LinkStyle>
      </li>
    </>
  );

  const textMenuContent = (
    <>
      {(rx_userAccount?.role ?? -1) >= ACCOUNT_ROLE.doctor.value && (
        <>
          <li
            className={pathname === `/${PAGE.PATIENTS}` ? ID.active : undefined}
          >
            <Link to={`/${PAGE.PATIENTS}`} onClick={onMenuClick}>
              <IntlMessages id="patient.title" />
            </Link>
          </li>
          {(rx_userAccount?.role ?? -1) >= ACCOUNT_ROLE.coordinator.value && (
            <li
              className={pathname === `/${PAGE.STAFF}` ? ID.active : undefined}
            >
              <Link to={`/${PAGE.STAFF}`} onClick={onMenuClick}>
                <IntlMessages id="staff.title" />
              </Link>
            </li>
          )}
        </>
      )}
      {(rx_userAccount?.role ?? -1) === ACCOUNT_ROLE.patient.value && (
        <li
          className={
            pathname === `/${PAGE.PATIENT}/${rx_userAccount?.key}`
              ? ID.active
              : undefined
          }
        >
          <Link
            to={`/${PAGE.PATIENT}/${rx_userAccount?.key}`}
            onClick={onMenuClick}
          >
            <IntlMessages id="patient.myMedicalHistory" />
          </Link>
        </li>
      )}
    </>
  );

  switch (menuPart) {
    case 'text':
      return textMenuContent;
    case 'popover':
      return popoverMenuContent;
    case 'mobile':
      return (
        <ul>
          {textMenuContent}
          {popoverMenuContent}
        </ul>
      );
    default:
      return <></>;
  }
};

type MenuBuilderProps = {
  menuPart?: string;
  onMenuClick?: () => void;
};

import actions from 'api/redux/actions';
import { TReducerProps } from 'api/redux/types';
import { ID } from 'functions/shared/constants';

const initState = {
  rx_commonSettings: {},
  rx_settingsData: {},

  rx_view: ID.desktop_view,
  rx_modalStatus: ID.none,
  rx_notificationStatus: ID.none,
  rx_notificationData: null,
  rx_logo: null,
};

export default function appReducer(
  state = initState,
  { type, payload }: TReducerProps
): Record<string, unknown> {
  switch (type) {
    // SET VIEW
    case actions.setView.TRIGGER:
      return { ...state, rx_view: payload };

    // SET MODAL
    case actions.setModal.TRIGGER:
      return { ...state, rx_modalStatus: payload };

    // SET NOTIFICATION
    case actions.setNotification.TRIGGER:
      return {
        ...state,
        rx_notificationStatus: payload.status ?? null,
        rx_notificationData: payload.data ?? null,
      };

    // LOAD LOGO
    case actions.loadLogo.SUCCESS:
      return { ...state, rx_logo: payload };

    // GET SETTINGS
    // case actions.getSettings.SUCCESS:
    //   return {
    //     ...state,
    //     rx_settingsData: payload,
    //   };

    default:
      return state;
  }
}

import actions from 'api/redux/actions';
import { TReducerProps } from 'api/redux/types';

const initState: Record<string, boolean> = {};
Object.keys(actions).forEach((a) => (initState[a] = false));

export default function loadingReducer(
  state = initState,
  { type }: TReducerProps
): Record<string, unknown> {
  const matches = /(.*)\/(REQUEST|SUCCESS|FAILURE)/.exec(type);
  // not a */REQUEST, */SUCCESS, */FAILURE actions, so we ignore them
  if (!matches) {
    return state;
  }
  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_FULFILL
    [requestName]: requestState === 'REQUEST',
  };
}

import { MenuOutlined } from '@ant-design/icons';
import { TReducerRoot } from 'api/redux/types';
import { ID } from 'functions/shared/constants';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import MenuBuilder from './MenuBuilder';
import {
  StyledHamburger,
  StyledMobileNavigation,
  StyledNavigation,
  StyledOverlay,
  StyledPopover,
  StyledPopoverContent,
  StyledTextMenu,
} from './Navigation.styles';

export default (): JSX.Element => {
  // File members
  const [mMobileMenuOpen, mSetMobileMenuOpen] = React.useState(false);
  const [mPopoverOpen, mSetPopoverOpen] = React.useState(false); // is dropdown visible?

  // Redux state
  const rx_userAccount = useSelector(
    (state: TReducerRoot) => state.GL_Auth.rx_userAccount
  );
  const rx_view = useSelector((state: TReducerRoot) => state.GL_App.rx_view);

  // const mUserInitials = React.useMemo(() => {
  //   if (rx_userAccount.name_first != null && rx_userAccount.name_last != null) {
  //     const regex = /[^a-zA-Z]/g;
  //     const letterOne = rx_userAccount.name_first
  //       .replace(regex, '')
  //       .substring(0, 1)
  //       .toUpperCase();
  //     const letterTwo = rx_userAccount.name_last
  //       .replace(regex, '')
  //       .substring(0, 1)
  //       .toUpperCase();
  //     return letterOne + letterTwo;
  //   } else {
  //     return '..';
  //   }
  // }, [rx_userAccount]);

  return (
    <>
      {rx_view === ID.mobile_view ? (
        <>
          <StyledHamburger
            className={mMobileMenuOpen ? 'open' : 'collapsed'}
            onClick={() => mSetMobileMenuOpen(!mMobileMenuOpen)}
          >
            <div className="icon">
              <span />
              <span />
              <span />
            </div>
          </StyledHamburger>
          <StyledOverlay
            // @ts-ignore
            active={mMobileMenuOpen}
            onClick={() => mSetMobileMenuOpen(false)}
          />
          <StyledMobileNavigation
            style={{
              left: mMobileMenuOpen ? '25%' : '100%',
            }}
          >
            <MenuBuilder
              menuPart="mobile"
              onMenuClick={() => {
                mSetMobileMenuOpen(false);
              }}
            />
          </StyledMobileNavigation>
        </>
      ) : (
        <StyledNavigation>
          <StyledTextMenu>
            {!_.isEmpty(rx_userAccount) && <MenuBuilder menuPart="text" />}
          </StyledTextMenu>
          <StyledPopover
            content={
              <StyledPopoverContent>
                <MenuBuilder
                  menuPart="popover"
                  onMenuClick={() => mSetPopoverOpen(false)}
                />
              </StyledPopoverContent>
            }
            trigger={ID.click}
            open={mPopoverOpen}
            onOpenChange={() => mSetPopoverOpen(!mPopoverOpen)}
            placement="bottomRight"
            // @ts-ignore
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <span>
              <MenuOutlined />
            </span>
          </StyledPopover>
        </StyledNavigation>
      )}
    </>
  );
};

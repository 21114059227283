import { UtilityStyles } from '@busybytes/style-constants';
import { ConfigProvider } from 'antd';
import Boot from 'api/redux/boot';
import { store } from 'api/redux/store';
import AppRouter from 'app/AppRouter';
import { AppLocale, defaultLanguage } from 'assets/i18n';
import GlobalStyles from 'assets/styles/globalStyles';
import { TLangIds } from 'functions/shared/types';
import { setLanguageCode } from 'library/firebase';
import { getLanguagePreference } from 'library/localStorage';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';

export const IntlContext = React.createContext({
  lang: getLanguagePreference() ?? defaultLanguage,
  switchAppLang: (_: TLangIds) => {},
});

const AppContainer = (): JSX.Element => {
  // File member
  const [mLang, mSetLang] = React.useState<TLangIds>(
    getLanguagePreference() ?? defaultLanguage
  );

  React.useEffect(() => {
    // init moment.js locale
    AppLocale[mLang].initMoment();

    // init Firebase language (used for auth emails)
    // TODO: this shouldn't be necessary anymore, as we don't send email from FBAuth anymore
    setLanguageCode(mLang);

    // init html lang attribute
    document.documentElement.lang = AppLocale[mLang].localeHtml;
  }, [mLang]);

  if (process.env.REACT_APP_FB_USE_EMULATOR) {
    console.info(
      '%c Using Firebase Emulator ',
      'background-color: black; font-size: 20px; color: cyan;'
    );
  }

  return (
    <Provider store={store}>
      <IntlContext.Provider
        value={{
          lang: mLang,
          switchAppLang: (key: TLangIds) => {
            mSetLang(key);
          },
        }}
      >
        <ConfigProvider locale={AppLocale[mLang].antd}>
          <IntlProvider
            locale={AppLocale[mLang].localeHtml}
            messages={AppLocale[mLang].messages}
          >
            <UtilityStyles />
            <GlobalStyles />
            <AppRouter />
          </IntlProvider>
        </ConfigProvider>
      </IntlContext.Provider>
    </Provider>
  );
};
Boot()
  .then(() => AppContainer())
  .catch((error) => console.error(error));

export default AppContainer;

export const PUBLIC_ROUTE = {
  LANDING: '/',
  LOGIN: 'login',
  REGISTRATION: 'registration',
};

export const TOOL = {
  SETTINGS: 'settings',
};

export const PAGE = {
  PATIENT: 'patient',
  PATIENTS: 'patients',
  STAFF: 'staff',
  P403: '403',
  P404: '404',
  P503: '503',
};

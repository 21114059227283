import { debounce } from 'lodash';
import React from 'react';

type ScreenSize = {
  width: number | undefined;
  height: number | undefined;
};

export const useWindowSize = (): ScreenSize => {
  const isClient = typeof window === 'object';

  const getSize = React.useCallback(
    () => ({
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }),
    [isClient]
  );

  const [size, setSize] = React.useState<ScreenSize>(getSize);

  React.useEffect(() => {
    if (!isClient) {
      return;
      // return false; // previously used
    }
    const onHandleResize = debounce(() => {
      setSize(getSize);
    }, 300);
    window.addEventListener('resize', onHandleResize);
    return () => window.removeEventListener('resize', onHandleResize);
  }, [getSize, isClient]);

  return size;
};
